import {react, useState, useEffect, useContext, useRef} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import postAPI from '../functions/API/postAPI.js'
import getAPI from '../functions/API/getAPI.js'
import GlobalContext from "../context/GlobalContext.js";
import { CheckIcon } from '@heroicons/react/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../components/loader.js';
import { Button } from '../components/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/dialog'
import { Description, Field, FieldGroup, Fieldset, Label, Legend } from '../components/fieldset'
import { Input } from '../components/input'
import { Heading } from '../components/heading'
import { Strong, Text, TextLink, Code } from '../components/text'
import PaymentSettings from './paymentsettings.js';
import { Select } from '../components/select'
import { Textarea } from '../components/textarea'
import { Navbar, NavbarItem, NavbarSection } from '../components/navbar'
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/dropdown'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table.jsx'
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid'
import Markdown from 'react-markdown'
import { Checkbox, CheckboxField, CheckboxGroup } from '../components/checkbox'
import Skeleton from 'react-loading-skeleton';
import { use } from 'framer-motion/client';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../components/description-list'

export default function IntegrationManager() {
    const [workingSettings, setWorkingSettings] = useState();
    const [savebutton, setSaveButton] = useState(false);
    const [APIEnabled, setAPIEnabled] = useState(false);
    const [APIUrl, setAPIUrl] = useState();
    const [updatedSettings, setUpdatedSettings] = useState({});
    const [IPs, setIPs] = useState();
    const [reload, setReload] = useState(false);
    const [loadingspinner, setLoadingSpinner] = useState(false);
    const [companies, setCompanies] = useState();
    const [selectedCompany, setSelectedCompany] = useState({'companyid': 0, 'companyname': "Not Set"});
    const {theme, updateTheme, themeCSS, settings} = useContext(GlobalContext);
    const [apiHealth, setAPIHealth] = useState('insufficient data') //healthy, degraded or error - default is error
    const [canCancelEnabled, setCanCancelEnabled] = useState();  
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    let [cxSettings, setCxSettings] = useState(false);
    let [koopidSettings, setKoopidSettings] = useState(false);
    let [allPaySettings, setAllPaySettings] = useState(false);
    let [sharepointIntegrationDialog, setSharepointIntegrationDialog] = useState(false);    
    let [sharepointTab, setSharepointTab] = useState('sites');
    let [sharepointSites, setSharepointSites] = useState([]);
    let [addSharepointSiteDialog, setAddSharepointSiteDialog] = useState(false);
    let [entraAppRegistrations, setEntraAppRegistrations] = useState([]);
    let [newSharepointSiteData, setNewSharepointSiteData] = useState({});
    let [integrations, setIntegrations] = useState([]);
    let [termsDialog, setTermsDialog] = useState(false);
    let [termsToAccept, setTermsToAccept] = useState([]);
    let [termsCheckBoxAccepted, setTermsCheckBoxAccepted] = useState(false);
    let [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    let [documentQueries, setDocumentQueries] = useState([]);
    let [addDocumentQueryDialog, setAddDocumentQueryDialog] = useState(false);
    let [availableLibraries, setAvailableLibraries] = useState();
    let [availableLibraryFields, setAvailableLibraryFields] = useState();
    let [newDocumentQueryData, setNewDocumentQueryData] = useState({check_parent_metadata: true});
    let [documentTypeLookupValues, setDocumentTypeLookupValues] = useState();
    let [documentTypeFieldType, setDocumentTypeFieldType] = useState();
    let [sharepointIntegrationToggle, setSharepointIntegrationToggle] = useState(false);
    let [getting, setGetting] = useState({sites: false, libraries: false, fields: false});
    const paymentSettingsRef = useRef();
    const triggerUpdateIntegrations = () => {
        if (paymentSettingsRef.current) {
            paymentSettingsRef.current.UpdateIntegrations();
        }
    };
    let [orphenedDocumentQuery, setOrphenedDocumentQuery] = useState(false);
    let [queryDetailDialog, setQueryDetailDialog] = useState(false);
    let [queryDetail, setQueryDetail] = useState();

    const toggleAPI = () => {
        setAPIEnabled(!APIEnabled);
        if (!APIEnabled) {
            enableAPI();
        } else {
            disableAPI();
        }
    };

    const toggleChat = () => {
        const newChatStatus = !workingSettings.chat_enabled;
        setWorkingSettings({...workingSettings, 'chat_enabled': newChatStatus});
        setUpdatedSettings({...updatedSettings, 'chat_enabled': newChatStatus});
        setSaveButton(true);
    };
    
    async function WorkingSettings() {
        if (settings.api_enabled == 1) {
            setAPIEnabled(true)
            setAPIHealth(settings.api_health);
        }
        setWorkingSettings(settings);
        setCanCancelEnabled(settings.api_repairs_can_cancel);
        setIPs(settings.proxyips)    
        setIsLoaded(true);
    }

    async function GetCompanies(){
        const companiesresponse = await getAPI('getcompanies');
        if (companiesresponse.response.ok) {
            setCompanies(companiesresponse.data)
            companiesresponse.data.map(function(item, index){
                if (item.companyid == settings.api_company_id) {
                    setSelectedCompany({...selectedCompany, 'companyid': item.companyid, 'companyname': item.companyname})
                }
            })
        } else {
            toast.error('Unable to get available companies');
        }
    }

    function SetCompany(companyid, companyname) {
        setSelectedCompany({...selectedCompany, 'companyid': companyid, 'companyname': companyname})
        setSaveButton(true)
        setAPIEnabled(true)
        setWorkingSettings({...workingSettings, 'api_company_id': companyid});
        setUpdatedSettings({...updatedSettings, 'api_company_id': companyid});
    }
    
    async function TestAPI() {
        setLoadingSpinner(true)
        const TestResponse = await getAPI('testproxyapi');
        if (TestResponse.response.ok) {
                toast.success('Integration test successful');
        } else {
            toast.error('Integration test failed. Please check settings.');
        }
        setLoadingSpinner(false);
    }

    function handleChange(event) {
        let settingname = event.target.name;
        let value = event.target.value;
        setWorkingSettings({...workingSettings, [settingname]: value});
        setUpdatedSettings({...updatedSettings, [settingname]: value});
        setSaveButton(true)
    }

    function enableAPI(){
        setSaveButton(true)
        setAPIEnabled(true)
        setWorkingSettings({...workingSettings, 'api_enabled': 1});
        setUpdatedSettings({...updatedSettings, 'api_enabled': 1});
        GetCompanies();
    }
    function disableAPI(){
        setSaveButton(true)
        setAPIEnabled(false)
        setWorkingSettings({...workingSettings, 'api_enabled': 0});
        setUpdatedSettings({...updatedSettings, 'api_enabled': 0});
    }
    

    async function UpdateIntegrations() {
        if (Object.keys(updatedSettings).length === 0) return;
        const body = updatedSettings
        const IntegrationSettingsResponse = await postAPI('settings', body);

        if(IntegrationSettingsResponse.response.ok){
            setSaveButton(false)
            setReload(!reload);
            toast.success('Integration Settings Updated Successfully.');
        }else{
            toast.error('Sorry an error occurred. Try again later.');
        }
    }

    function checkChanged(event) {
        if(event.target.id === 'api_repairs_can_cancel'){
            setCanCancelEnabled(event.target.checked);
            setUpdatedSettings({...updatedSettings, 'api_repairs_can_cancel': event.target.checked});
            setWorkingSettings({...workingSettings, 'api_repairs_can_cancel': event.target.checked});
        }
        setSaveButton(true);
        
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    async function getSharepointSites() {
        const response = await getAPI('admin/sharepoint/sites');
        if (response.response.ok) {
            setSharepointSites(response.data);
        } else {
            toast.error('Unable to retrieve sites');
        }
    }

    async function getEntraAppRegistrations() {
        const response = await getAPI('admin/entra/registrations');
        if (response.response.ok) {
            setEntraAppRegistrations(response.data);
        } else {
            toast.error('Unable to retrieve app registrations');
        }
    }

    async function createSharepointSite(){
        if(!newSharepointSiteData.entra_auth_id){
            toast.error('Please select an Entra App Registration') 
            return;
        }
        const response = await postAPI('admin/sharepoint/site', newSharepointSiteData)
        if(response.response.ok){
            toast.success('Site created successfully')
            getSharepointSites()
            setAddSharepointSiteDialog(false);
            setNewSharepointSiteData('')
        } else {
            toast.error('Unable to create site')
        }
    }

    async function getDocumentQueries() {
        setOrphenedDocumentQuery(false);

        const response = await getAPI('admin/sharepoint/documents/queries');
        if (response.response.ok) {
            setDocumentQueries(response.data);
            response.data.forEach(query => {
                if (query.orphaned) {
                    toast.warning(`Document query: ${query.name} requires attention`);
                    setOrphenedDocumentQuery(true);
                }
            });
        } else {
            toast.error('Unable to retrieve queries');
        }
    }

    async function getIntegrations() {
        const response = await getAPI('integrations');
        if (response.response.ok) {
            setIntegrations(response.data);
            response.data.forEach(data => {
                if (data.name == 'sharepoint') {
                    if (data.enabled == 0) {
                    setSharepointIntegrationToggle(false);
                    } else {
                    setSharepointIntegrationToggle(true);
                    }
                }
            });
        } else {
            toast.error('Unable to retrieve integrations');
        }
    }

    async function getLibraries(siteid) {
        setGetting({...getting, libraries: true});
        if (siteid == 'ignore') {
            return;
        }
        const response = await getAPI('admin/sharepoint/libraries/' + siteid);
        if (response.response.ok) {
            setAvailableLibraries(response.data);
        } else {
            toast.error('Unable to retrieve integrations');
        }
        setGetting({...getting, libraries: false});
    }

    async function getLibraryFields(libraryid) {
        setGetting({...getting, fields: true});
        if (libraryid == 'ignore') {
            return;
        }
        const response = await getAPI('admin/sharepoint/libraries/fields/' + libraryid);
        if (response.response.ok) {
            setAvailableLibraryFields(response.data);
        } else {
            toast.error('Unable to retrieve integrations');
        }
        setGetting({...getting, fields: false});

    }

    async function createDocumentQuery(){
        const response = await postAPI('admin/sharepoint/documents/query', newDocumentQueryData)
        if(response.response.ok){
            toast.success('Query created successfully')
            getDocumentQueries()
            setAddDocumentQueryDialog(false);
            setNewDocumentQueryData({check_parent_metadata: true});
        } else {
            toast.error('Unable to create query')
        }
    }

    async function deleteDocumentQuery(queryid){
        const response = await postAPI('admin/sharepoint/documents/query/delete', {id: queryid})
        if(response.response.ok){
            toast.success('Query deleted successfully')
            getDocumentQueries()
        } else {
            toast.error('Unable to delete query')
        }
    }

    async function  toggleSharepointIntegration () {
        setSharepointIntegrationToggle(!sharepointIntegrationToggle);
        let status = !sharepointIntegrationToggle;
        const response = await postAPI('integrations/toggle', {name: 'sharepoint', enabled: status });
        if (response.response.ok) {
            toast.success('Integration status changed');
        } else {
            toast.error('Unable to enable integration');
        }
    };
        

    function displayTerms(integration) {
        setTermsDialog(true);
        setTermsToAccept({...termsToAccept, id: integration.id, name: integration.name, description: integration.description, terms: integration.terms.content});
    }

    async function acceptTerms(integration) {
        let body = {'integration': integration};  
        const response = await postAPI('integrations/terms/accept', body);

        if (response.response.ok) {
            getIntegrations();
            setTermsDialog(false);
        } else {
            toast.error('Unable to retrieve integrations');
        }
    }


    const termsRef = useRef(null);
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
        if (scrollTop + clientHeight >= scrollHeight) {
            setIsScrolledToBottom(true);
        }
    };

    function setAndOpenQueryDetail(query) {
        setQueryDetail({...queryDetail, ...query});
        setQueryDetailDialog(true);
    }

    useEffect(() => {
        WorkingSettings();
        getIntegrations();
    }, []);
    
    return (
        <div className="">
            <AdminSidebar>
            {loading ? (
                <Loader style='form' setLoadingState={setLoading} isLoaded={isLoaded}/>
            ) : (
                <>
                <div className="sm:flex-auto mb-4 border-b border-gray-900/10 pb-2">
                    <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">Integration Manager</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Configure integrations to your backend systems.
                    </p>
                </div>

                <div className="flex w-full mb-4 flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                    <div>
                    <Heading>Civica Cx</Heading>
                    <Text>
                        Configure your integration with Civica Cx. Need help? Visit {' '}
                        <TextLink href="https://docs.tenantwise.co.uk/tenantwise-documentation/admin-console/integrations/housing-management/civica-cx">our documentation</TextLink>.
                        </Text>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={()=>{GetCompanies(), setCxSettings(true)}} outline>Edit</Button>
                    </div>
                </div>
                <div className="flex w-full mb-4  flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                    <div>
                    <Heading>Koopid Chat</Heading>
                    <Text>
                        Configure your integration with Koopid Live Chat Service. Need help? Visit {' '}
                        <TextLink href="https://docs.tenantwise.co.uk/tenantwise-documentation/admin-console/integrations/chat/koopid-chat">our documentation</TextLink>.
                        </Text>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={()=>{setKoopidSettings(true)}} outline>Edit</Button>
                    </div>
                </div>

                <div className="flex w-full mb-4 flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                    <div>
                    <Heading>AllPay Payment & Direct Debit</Heading>
                    <Text>
                        Configure your integration with allpay. Need help? Visit {' '}
                        <TextLink href="https://docs.tenantwise.co.uk/tenantwise-documentation/admin-console/">our documentation</TextLink>.
                        </Text>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={()=>{setAllPaySettings(true)}} outline>Edit</Button>
                    </div>
                </div>

                {integrations && integrations.map((integration, index) => {
                    if (integration.name == 'sharepoint') {
                        return (
                            <div className="flex w-full mb-4 flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                            <div>
                            <Heading>SharePoint Document Storage</Heading>
                            <Text>
                                Configure your integration with Microsoft SharePoint. Need help? Visit {' '}
                                <TextLink href="https://docs.tenantwise.co.uk/tenantwise-documentation/admin-console/">our documentation</TextLink>.
                                </Text>
                            </div>
                            <div className="flex gap-4">
                                {integration.terms.accepted ? (
                                    <>
                                        <div className="sm:col-span-4">
                                            <Switch.Group as="div" className="flex items-center">
                                                <Switch
                                                    checked={sharepointIntegrationToggle}
                                                    onChange={toggleSharepointIntegration}
                                                    className={classNames(
                                                    sharepointIntegrationToggle ? 'bg-blue-700' : 'bg-gray-200',
                                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 group mr-2'
                                                    )}
                                                >
                                                    <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                    sharepointIntegrationToggle && 'right-0 absolute',
                                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                    )}
                                                    />
                                                </Switch>
                                                <Button onClick={()=>{setSharepointIntegrationDialog(true), getSharepointSites(), getDocumentQueries()}} outline>Edit</Button>
                                            </Switch.Group>
                                        </div>
                                    </>
                                ):(
                                    <div>
                                        <TextLink style={{ cursor: 'pointer' }} onClick={()=>{displayTerms(integration)}} className={'pr-2'}>Accept terms to enable</TextLink>
                                        <Button style={{ cursor: 'not-allowed' }}disabled outline>Edit</Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )   
                }
                })}

                <Dialog open={cxSettings} onClose={()=>{setCxSettings(false)}} size="4xl">
                    <DialogTitle>Civica Cx</DialogTitle>
                    <DialogDescription>
                        Connect to your Civica Cx System API
                    </DialogDescription>
                    <DialogBody>
                        <div className="">
                        <form className="">
                            <div className="space-y-6">
                                <div className="">
                                    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-4">
                                        <Switch.Group as="div" className="flex items-center">
                                        <Switch
                                            checked={APIEnabled}
                                            onChange={toggleAPI}
                                            className={classNames(
                                            APIEnabled ? 'bg-blue-700' : 'bg-gray-200',
                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 group'
                                            )}
                                        >
                                            <span
                                            aria-hidden="true"
                                            className={classNames(
                                                APIEnabled && 'right-0 absolute',
                                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                            />
                                        </Switch>
                                        <Switch.Label as="span" className="ml-3 text-sm">
                                            <span className="font-medium text-gray-900">API integration {APIEnabled ? 'enabled' : 'disabled'}</span>
                                        </Switch.Label>
                                        </Switch.Group>
                                        </div>
                                    </div>
                                    {APIEnabled && (
                                        <>
                                        <h2 className="mt-6 text-base font-semibold leading-7 text-gray-900">API settings</h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-600">Customise the settings for how TenantWise connects to your backend API.</p>
                                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                            <div className="sm:col-span-3">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Endpoint URL
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        placeholder="https://api.yourhms.co.uk"
                                                        value={workingSettings.api_endpoint}
                                                        name="api_endpoint"
                                                        onChange={handleChange}
                                                        type="text"
                                                        id="api_endpoint"
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Username
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="API Username"
                                                        value={workingSettings.api_username}
                                                        name="api_username"
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Password
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="password"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="API Password"
                                                        value={workingSettings.api_password}
                                                        name="api_password"
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-3">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Selected company
                                                </label>
                                                {companies && selectedCompany ? (
                                                    <select
                                                        id="location"
                                                        name="location"
                                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-700 sm:text-sm sm:leading-6"
                                                        value={selectedCompany.companyname || "default"}
                                                        onChange={(e) => {
                                                            const selectedCompany = companies.find(company => company.companyname === e.target.value);
                                                            if (selectedCompany) {
                                                                SetCompany(selectedCompany.companyid, selectedCompany.companyname);
                                                            }
                                                        }}                                                >   
                                                        <option value="default" disabled>Please select a company</option>
                                                        {companies && (
                                                            companies.map(function (item, index) {
                                                                return (
                                                                    <option key={index} value={item.companyname}>
                                                                        {item.companyname}
                                                                    </option>
                                                                );
                                                            })
                                                        )}
                                                    </select>
                                                ) : (
                                                    <p className="mt-4 text-sm leading-6 text-gray-600">Loading...</p>
                                                )}
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Testing
                                                </label>
                                                {loadingspinner && (
                                                    <button
                                                        type='button'
                                                        className="mt-2 block w-full items-center gap-x-2 rounded-md bg-blue-700 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <div role="status">
                                                            <svg aria-hidden="true" className="mr-2 h-[20px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                )}
                                                {!loadingspinner && APIEnabled && (
                                                    <button
                                                        type='button'
                                                        onClick={() => { TestAPI(); } }
                                                        className="mt-2 w-full items-center gap-x-2 rounded-md bg-blue-700 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                                        <div role="status h-full">
                                                            Run test
                                                        </div>
                                                    </button>
                                                )}
                                            </div>
                                            <div className="sm:col-span-5">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    API health
                                                </label>
                                                <div className="mt-2">
                                                    <div className="grid grid-cols-6 space-between w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6">
                                                        <div className="items-start p-4 col-span-5">
                                                            <div className="w-full">
                                                                {apiHealth && apiHealth == 'healthy' && (
                                                                    <>
                                                                        <p className="text-sm text-left font-medium text-gray-900">API Quality: Excellent</p>
                                                                        <p className="mt-1 text-sm text-left text-gray-500">The API response time is less than 1 second</p>
                                                                    </>
                                                                )}
                                                                {apiHealth && apiHealth == 'degraded' && (
                                                                    <>
                                                                        <p className="text-sm text-left font-medium text-gray-900">API Quality: Degraded</p>
                                                                        <p className="mt-1 text-sm text-left text-gray-500">The API response time is greater than 1 second</p>
                                                                    </>
                                                                )}
                                                                {apiHealth && apiHealth == 'error' && (
                                                                    <>
                                                                        <p className="text-sm text-left font-medium text-gray-900">API Quality: Error</p>
                                                                        <p className="mt-1 text-sm text-left text-gray-500">The API test failed</p>
                                                                    </>
                                                                )}
                                                                {apiHealth && apiHealth == 'insufficient data' && (
                                                                    <>
                                                                        <p className="text-sm text-left font-medium text-gray-900">API Quality: Insufficient Data</p>
                                                                        <p className="mt-1 text-sm text-left text-gray-500">Insufficient data to perform quality test</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1 grid justify-end align-middle content-center mr-4">
                                                            <div type="button" className="rounded-none rounded-tr-lg border border-transparent text-sm font-medium text-blue-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                                                                {apiHealth && apiHealth == 'healthy' && (
                                                                    <div className="mx-auto  flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                                                    </div>
                                                                )}
                                                                {apiHealth && apiHealth == 'degraded' && (
                                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-amber-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                        <ExclamationTriangleIcon className="h-6 w-6 text-amber-600" aria-hidden="true" />
                                                                    </div>
                                                                )}
                                                                {apiHealth && apiHealth == 'error' && (
                                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                                                    </div>
                                                                )}
                                                                {apiHealth && apiHealth == 'insufficient data' && (
                                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                        <InformationCircleIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 grid grid-cols-1 sm:grid-cols-1">
                                            <label className="block text-md font-medium leading-6 text-gray-900">
                                                API refresh
                                            </label>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">The time in minutes that we cache data from your backend API.</p>
                                        </div>
                                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                            <div className="sm:col-span-2">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Agreements
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="Agreements refresh in minutes"
                                                        value={workingSettings.api_agreements_refresh_minutes}
                                                        name="api_agreements_refresh_minutes"
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Repairs
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="Repairs refresh in minutes"
                                                        value={workingSettings.api_repairs_refresh_minutes}
                                                        name="api_repairs_refresh_minutes"
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Accounts
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="Accounts refresh in minutes"
                                                        value={workingSettings.api_accounts_refresh_minutes}
                                                        name="api_accounts_refresh_minutes"
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                            <div className="sm:col-span-2">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Requests
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="Requests refresh in minutes"
                                                        value={workingSettings.api_requests_refresh_minutes}
                                                        name="api_requests_refresh_minutes"
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Assets
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="Assets refresh in minutes"
                                                        value={workingSettings.api_assets_refresh_minutes}
                                                        name="api_assets_refresh_minutes"
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1">
                                            <label className="block text-md font-medium leading-6 text-gray-900">
                                                Repairs
                                            </label>
                                        </div>
                                        <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                            <div className="sm:col-span-4">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Start date
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="date"
                                                        id="repairsstart"
                                                        name="api_repairs_start_date"
                                                        placeholder="Repairs start date"
                                                        value={workingSettings.api_repairs_start_date}
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>                            
                                        </div>
                                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                            <div className="sm:col-span-6">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Cancellation
                                                </label>
                                                <div className="relative flex gap-x-3">
                                                    <div className="mt-2 flex h-6 items-center">
                                                        <input
                                                        id="api_repairs_can_cancel"
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                                        onChange={checkChanged}
                                                        checked={canCancelEnabled}
                                                        />
                                                    </div>
                                                    <div className="mt-2 text-sm leading-6">
                                                        <label htmlFor="transactions" className="font-medium text-gray-900">
                                                        Enabled
                                                        </label>
                                                        <p className="text-gray-500">Allow customers to cancel their repairs</p>
                                                    </div>
                                                </div>
                                            </div>                              
                                        </div>
                                        {canCancelEnabled ? (
                                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                            <div className="sm:col-span-4">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Notice for cancellation in days
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="Notice period for cancellation"
                                                        value={workingSettings.api_repairs_cancel_days}
                                                        name="api_repairs_cancel_days"
                                                        onChange={handleChange}                                                
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>      
                                        </div>
                                        ):(<></>)}
                                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                            <div className="sm:col-span-4">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Repairs emergency number
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="Emergency contact number"
                                                        value={workingSettings.repair_emergency_number}
                                                        name="repair_emergency_number"
                                                        onChange={handleChange}                                                
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>      
                                        </div>
                                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                            <div className="sm:col-span-6">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Repairs help text
                                                </label>
                                                <div className="mt-2">
                                                    <textarea
                                                        id='repair_help_text'
                                                        rows={3}
                                                        placeholder="Additional help text for repairs"
                                                        value={workingSettings.repair_help_text}
                                                        name="repair_help_text"
                                                        onChange={handleChange}                                                
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>      
                                        </div>
                                        <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-1">
                                            <label className="block text-md font-medium leading-6 text-gray-900">
                                                IP address management
                                            </label>
                                            <div className="text-sm text-gray-700">If the API you are connecting to has a firewall or web application firewall protecting it, you may need to allow the IP addresses below:</div>
                                            <div className="grid grid-cols-1 md:grid-cols-1 text-left">
                                            {IPs && IPs != 'Unable to connect to proxy' ?(
                                            IPs.map(function(ip, index){
                                                return (
                                                    <div className="text-sm flex min-w-0 flex-1 gap-2">
                                                        <span className="truncate font-medium">{ip.IP}</span>
                                                        <span className="flex-shrink-0 text-gray-400">HTTPS</span>
                                                    </div>)
                                                })
                                            ):(
                                                <div className="text-sm flex min-w-0 flex-1 gap-2">
                                                    <span className="truncate font-medium">Unable to retrieve IPs</span>
                                                    <span className="flex-shrink-0 text-gray-400">Try again later</span>
                                                </div>
                                            )}
                                            </div>
                                        </div>         
                                        </>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    </DialogBody>
                    <DialogActions>
                    <Button plain onClick={() => setCxSettings(false)}>
                        Cancel
                    </Button>
                    <Button color="blue" onClick={() => [setCxSettings(false), UpdateIntegrations()]}>Save</Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={koopidSettings} onClose={()=>{setKoopidSettings(false)}} size='4xl'>
                    <DialogTitle>Koopid Chat</DialogTitle>
                    <DialogDescription>
                        Connect to your Koopid live chat service.
                    </DialogDescription>
                    <DialogBody>
                    {settings && workingSettings && (
                        <div className="border-b border-gray-900/10 pb-8">
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                <div className="sm:col-span-4">
                                    <Switch.Group as="div" className="flex items-center">
                                    <Switch
                                        checked={workingSettings.chat_enabled}
                                        onChange={toggleChat}
                                        className={classNames(
                                            workingSettings.chat_enabled ? 'bg-blue-600' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2'
                                        )}
                                    >
                                        <span
                                        aria-hidden="true"
                                        className={classNames(
                                            workingSettings.chat_enabled && 'right-0 absolute',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                        />
                                    </Switch>
                                    <Switch.Label as="span" className="ml-3 text-sm">
                                        <span className="font-medium text-gray-900">Chat integration {workingSettings.chat_enabled ? 'enabled' : 'disabled'}</span>
                                    </Switch.Label>
                                    </Switch.Group>
                                </div>
                            </div>
                            {workingSettings.chat_enabled ? (    
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">     
                                <div className="sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Provider
                                    </label>                  
                                    <select
                                        className="mt-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        onChange={(e) => {
                                            const selectedProvider = e.target.value;
                                            if (selectedProvider !== "default") {
                                                setWorkingSettings({...workingSettings, 'chat_provider': selectedProvider});
                                                setUpdatedSettings({...updatedSettings, 'chat_provider': selectedProvider});
                                                setSaveButton(true);
                                            }
                                        }}
                                        defaultValue={workingSettings.chat_provider || "default"}
                                    >
                                    <option value="default" disabled>Please select a provider</option>
                                    <option value="koopid">Koopid</option>
                                    </select>
                                </div>
                            </div>
                            ):(<></>)}
                            {workingSettings.chat_enabled && workingSettings.chat_provider == 'koopid' ? (
                            <>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Koopid settings
                                </label>
                            </div>
                            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                <div className="sm:col-span-3">
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            color="blue"
                                            outline={true}
                                            placeholder="Koopid region URL"
                                            value={workingSettings.koopid_region}
                                            name="koopid_region"
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            color="blue"
                                            outline={true}
                                            placeholder="Koopid support email address"
                                            value={workingSettings.koopid_email}
                                            name="koopid_email"
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            color="blue"
                                            outline={true}
                                            placeholder="Koopid tag"
                                            value={workingSettings.koopid_tag}
                                            name="koopid_tag"
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            color="blue"
                                            outline={true}
                                            placeholder="Koopid Mobile App URL"
                                            value={workingSettings.koopid_mobile_app_url}
                                            name="koopid_mobile_app_url"
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                            </div>
                            </>
                        ):(<></>)}
                        </div>    
                        )}
                    </DialogBody>
                    <DialogActions>
                    <Button plain onClick={() => setKoopidSettings(false)}>
                        Cancel
                    </Button>
                    <Button color='blue' onClick={() => [UpdateIntegrations(), setKoopidSettings(false)]}>Save</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={allPaySettings} onClose={()=>{setAllPaySettings(false)}} size='5xl'>
                    <DialogTitle>AllPay configuration</DialogTitle>
                    <DialogDescription>
                        Connect and configure your allpay integrations.
                    </DialogDescription>
                    <DialogBody>
                        <PaymentSettings ref={paymentSettingsRef}/>
                    </DialogBody>
                    <DialogActions>
                    <Button plain onClick={() => setAllPaySettings(false)}>
                        Cancel
                    </Button>
                    <Button color='blue' onClick={() => [triggerUpdateIntegrations(), setAllPaySettings(false)]}>Save</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={sharepointIntegrationDialog} onClose={()=>{setSharepointIntegrationDialog(false)}} size='2xl'>
                    <DialogTitle>SharePoint Document Integration</DialogTitle>
                    <DialogDescription>
                        Connect to your SharePoint sites and libraries.
                    </DialogDescription>
                    <DialogBody>
                        <Navbar className={'-ml-2 -mt-6 mb-4'}>
                            <NavbarSection>
                                {sharepointTab == 'sites' ? (   
                                    <NavbarItem current onClick={() => setSharepointTab('sites')}>Sites</NavbarItem>
                                ) : (
                                    <NavbarItem onClick={() => setSharepointTab('sites')}>Sites</NavbarItem>
                                )}
                                {sharepointTab == 'queries' ? (   
                                    <NavbarItem current onClick={() => setSharepointTab('queries')}>
                                        <div className='flex'>
                                            Document Queries
                                            {!!orphenedDocumentQuery && (
                                                <ExclamationTriangleIcon width={20} height={20} className='ml-2 text-red-500 text-bold' title="A query requires attention"/>
                                            )}
                                        </div>
                                    </NavbarItem>
                                ) : (
                                    <NavbarItem onClick={() => setSharepointTab('queries')}>
                                        <div className='flex'>
                                            Document Queries
                                            {!!orphenedDocumentQuery && (
                                                <ExclamationTriangleIcon width={20} height={20} className='ml-2 text-red-500 text-bold' title="A query requires attention"/>
                                            )}
                                        </div>
                                    </NavbarItem>
                                )}
                            </NavbarSection>
                        </Navbar>
                        
                        {sharepointTab == 'sites' ? (
                            <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
                                <TableHead>
                                    <TableRow>
                                    <TableHeader>Site Name</TableHeader>
                                    <TableHeader className="relative w-0">
                                        <span className="sr-only">Actions</span>
                                    </TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sharepointSites && sharepointSites.map((site) => (
                                    <TableRow key={site.id}>
                                        <TableCell className="font-medium">{site.name}</TableCell>
                                        <TableCell>
                                        <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                            <Dropdown>
                                            <DropdownButton plain aria-label="More options">
                                                <EllipsisHorizontalIcon />
                                            </DropdownButton>
                                            <DropdownMenu anchor="bottom end">
                                                {/* <DropdownItem disabled>Edit</DropdownItem> */}
                                                <DropdownItem>Delete</DropdownItem>
                                            </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ):(
                            <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
                                <TableHead>
                                    <TableRow>
                                    <TableHeader>Query Name</TableHeader>
                                    <TableHeader className="relative w-0">
                                        <span className="sr-only">Actions</span>
                                    </TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {documentQueries && documentQueries.map((doc) => (
                                    <TableRow key={doc.id}>
                                        <TableCell className="font-medium">
                                            <div className={'flex'}>
                                                {doc.name}
                                                {!!doc.orphaned && (
                                                    <ExclamationTriangleIcon width={20} height={20} className='ml-2 text-red-500 text-bold' title="A field relating to this query has been removed or is invalid. Please delete this query and re-create it."/>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                        <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                            <Dropdown>
                                            <DropdownButton plain aria-label="More options">
                                                <EllipsisHorizontalIcon />
                                            </DropdownButton>
                                            <DropdownMenu anchor="bottom end">
                                                <DropdownItem onClick={() => setAndOpenQueryDetail(doc)}>View</DropdownItem>
                                                <DropdownItem onClick={()=>{deleteDocumentQuery(doc.id)}}>Delete</DropdownItem>
                                            </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                        
                    </DialogBody>
                    <DialogActions>
                    <Button plain onClick={() => {setSharepointIntegrationDialog(false), setSharepointTab('sites')}}>
                        Cancel
                    </Button>
                    {sharepointTab == 'sites' ? (
                        <Button color='blue' onClick={() => [setAddSharepointSiteDialog(true), getEntraAppRegistrations()]}>Add Site</Button>
                    ) : (
                        <Button color='blue' onClick={() => [setAddDocumentQueryDialog(true)]}>Add Query</Button>
                    )}
                    </DialogActions>
                </Dialog>

                <Dialog open={addSharepointSiteDialog} onClose={()=>{setAddSharepointSiteDialog(false)}} size='2xl'>
                    <DialogTitle>New SharePoint Site</DialogTitle>
                    <DialogDescription>
                        Enter the required details to link a SharePoint site ready to create a new document query.
                    </DialogDescription>
                    <DialogBody>
                        <Fieldset>
                            <FieldGroup>
                            <Field>
                                <Label>Site Guid</Label>
                                <Input onChange={(e)=>{setNewSharepointSiteData({...newSharepointSiteData, site_guid: e.target.value})}} required name="site-guid" />
                            </Field>
                            <Field>
                                <Label>Entra App Registration</Label>
                                <Select required name="entra-app-registration" onChange={(e)=>{setNewSharepointSiteData({...newSharepointSiteData, entra_auth_id: e.target.value})}}>
                                    <option>Select an Entra App Registration</option>
                                    {entraAppRegistrations && entraAppRegistrations.map((registration) => (
                                    <option key={registration.id} value={registration.id}>{registration.name}</option>
                                    ))}
                                </Select>
                                <Description>The Entra App Registration selected requires permission to the relavent SharePoint site.</Description>
                            </Field>
                            </FieldGroup>
                        </Fieldset>
                    </DialogBody>
                    <DialogActions>
                    <Button plain onClick={() => setAddSharepointSiteDialog(false)}>
                        Cancel
                    </Button>
                    <Button color='blue' onClick={() => {createSharepointSite()}}>Save</Button>

                    </DialogActions>
                </Dialog>

                <Dialog open={addDocumentQueryDialog} onClose={()=>{setAddDocumentQueryDialog(false)}} size='2xl'>
                    <DialogTitle>New Document Query</DialogTitle>
                    <DialogDescription>
                        A document query is used to construct a search query to retrieve documents from a SharePoint site.
                    </DialogDescription>
                    <DialogBody>
                        <Fieldset>
                            <FieldGroup>
                                <Field>
                                    <Label>Query Name</Label>
                                    <Input required onChange={(e)=>{setNewDocumentQueryData({...newDocumentQueryData, name: e.target.value})}} name="name" />
                                </Field>
                                <Field>
                                    <Label>Document Name</Label><Text>This is used to mask the document name. Leave blank if you wan't to use the actual name</Text>
                                    <Input onChange={(e)=>{setNewDocumentQueryData({...newDocumentQueryData, rename_value: e.target.value})}} required name="rename_value" />
                                </Field>
                                <CheckboxField className={''}>
                                    <Checkbox  name="accept-terms" defaultChecked onChange={(checked) => {setNewDocumentQueryData({...newDocumentQueryData, check_parent_metadata: checked})}} />
                                    <Label>Check parent folder metadata matches</Label>
                                    <Description>Check that both the document and its parent folder have matching metadata</Description>
                                </CheckboxField>
                                <Field>
                                    <Label>Site</Label>
                                    <Select required name="entra-app-registration" onChange={(e)=>{getLibraries(e.target.value)}}>
                                        <option value='ignore'>Select a SharePoint Site</option>
                                        {sharepointSites && sharepointSites.map((site) => (
                                        <option key={site.id} value={site.id}>{site.name}</option>
                                        ))}
                                    </Select>
                                </Field>

                                {getting.libraries && (
                                    <div className='pt-2'>
                                        <Skeleton width={200} rows={1}/>
                                        <Skeleton rows={1}/>
                                    </div>
                                )}

                                <Field>
                                {availableLibraries && (
                                    <>
                                    <Label>Library</Label>
                                    <Select required name="entra-app-registration" onChange={(e)=>{getLibraryFields(e.target.value), setNewDocumentQueryData({...newDocumentQueryData, library_id: e.target.value})}}>
                                        <option>Select a SharePoint Site</option>
                                        {availableLibraries.map((library) => (
                                        <option key={library.id} value={library.id}>{library.name}</option>
                                        ))}
                                    </Select>
                                    </>
                                )}
                                </Field>

                                {getting.fields && (
                                    <>
                                    <div className='pt-2'>
                                        <Skeleton width={200} rows={1}/>
                                        <Skeleton rows={1}/>
                                    </div>
                                        <div className='pt-2'>
                                        <Skeleton width={200} rows={1}/>
                                        <Skeleton rows={1}/>
                                    </div>
                                        <div className='pt-2'>
                                        <Skeleton width={200} rows={1}/>
                                        <Skeleton rows={1}/>
                                    </div>
                                    </>
                                )}

                                <Field>
                                {availableLibraryFields && (
                                    <>
                                    <Label>Agreement Field</Label>
                                    <Select required name="agreement-field" onChange={(e)=>{setNewDocumentQueryData({...newDocumentQueryData, agreement_field_id: e.target.value})}}>
                                        <option value={'ignore'}>Select the field that contains the Agreement Reference</option>
                                        {availableLibraryFields.filter(field => field.is_lookup === 0 && field.is_boolean === 0).map((field) => (
                                        <option key={field.id} value={field.id}>{field.display_name}</option>
                                        ))}
                                    </Select>
                                    </>
                                )}
                                </Field>

                                <Field>
                                {availableLibraryFields && (
                                    <>
                                    <Label>Document Type Field</Label>
                                    <Select required name="document-type-field" 
                                    onChange={(e) => {
                                        const selectedOption = e.target.selectedOptions[0];
                                        const selectedDocumentType = selectedOption.dataset.selectedDocumentType;
                                        setNewDocumentQueryData({
                                            ...newDocumentQueryData,
                                            document_type_field_id: e.target.value,
                                        });
                                        const documentType = JSON.parse(selectedDocumentType);
                                        if (documentType.lookup_values) {
                                            setDocumentTypeLookupValues(documentType.lookup_values);
                                            setDocumentTypeFieldType('lookup');
                                        } else {
                                            setDocumentTypeFieldType('string');
                                        }
                                    }}>
                                        <option value={'ignore'}>Select the field that contains the document type</option>
                                        {availableLibraryFields.filter(field => !field.is_boolean).map((field) => (
                                            <>
                                                <option key={field.id} data-selected-document-type={JSON.stringify(field)} value={field.id}>{field.display_name} {!!field.is_lookup && '(Lookup)'}</option>
                                            </>
                                        ))}
                                    </Select>
                                    </>
                                )}
                                </Field>

                                <Field>
                                {documentTypeFieldType == 'lookup' && (
                                    <>
                                    <Label>Document Type Field Lookup Value</Label>
                                    <Select required name="document_type_field_value" onChange={(e)=>{setNewDocumentQueryData({...newDocumentQueryData, document_type_field_lookup_value: e.target.value})}}>
                                        <option value={'ignore'}>Select the lookup that contains the document type value</option>
                                        {documentTypeLookupValues.map((value) => (
                                        <option key={value.id} value={value.id}>{value.title}</option>
                                        ))}
                                    </Select>
                                    </>
                                )}
                                </Field>

                                <Field>
                                {documentTypeFieldType == 'string' && (
                                    <Field>
                                        <Label>Document Type Value</Label>
                                        <Input onChange={(e)=>{setNewDocumentQueryData({...newDocumentQueryData, document_type_field_value: e.target.value})}} name="document_type_field_value" />
                                    </Field>
                                )}
                                </Field>

                                <Field>
                                {availableLibraryFields && (
                                    <>
                                    <Label>Visable To Tenant Field</Label>
                                    <Select required name="document-visable-to-tenant-field" onChange={(e)=>{setNewDocumentQueryData({...newDocumentQueryData, visible_to_tenant_field_id: e.target.value})}}>
                                        <option value={'ignore'}>Select the visable to tenant field</option>
                                        {availableLibraryFields.filter(field => field.is_boolean).map((field) => (
                                            <>
                                                <option key={field.id} value={field.id}>{field.display_name} {!!field.is_boolean && '(True/False)'}</option>
                                            </>
                                        ))}
                                    </Select>
                                    </>
                                )}
                                </Field>
                            </FieldGroup>
                        </Fieldset>
                    </DialogBody>
                    <DialogActions>
                    <Button plain onClick={() => setAddDocumentQueryDialog(false)}>
                        Cancel
                    </Button>
                    <Button color='blue' onClick={() => {createDocumentQuery()}}>Save</Button>

                    </DialogActions>
                </Dialog>

                {queryDetail && (
                    <Dialog open={queryDetailDialog} onClose={()=>{setQueryDetailDialog(false)}} size='3xl'>
                        <DialogTitle>Query Detail</DialogTitle>
                        <DialogDescription>
                            Here is the details relating to the selected query.
                        </DialogDescription>
                        <DialogBody>
                            <DescriptionList>
                                <DescriptionTerm>Query Name</DescriptionTerm>
                                <DescriptionDetails>{queryDetail.name}</DescriptionDetails>
                                <DescriptionTerm>Rename Value</DescriptionTerm>
                                <DescriptionDetails>{queryDetail.rename_value}</DescriptionDetails>
                                <DescriptionTerm>Agreement Field Name</DescriptionTerm>
                                <DescriptionDetails>{queryDetail.agreement_field_name}</DescriptionDetails>
                                <DescriptionTerm>Document Type Field Name</DescriptionTerm>
                                <DescriptionDetails>{queryDetail.document_type_field_name}</DescriptionDetails>
                                <DescriptionTerm>Document Type Lookup Value</DescriptionTerm>
                                <DescriptionDetails>{queryDetail.document_type_field_lookup_title}</DescriptionDetails>
                                <DescriptionTerm>Document Type Field Value</DescriptionTerm>
                                <DescriptionDetails>{queryDetail.document_type_field_value}</DescriptionDetails>
                                <DescriptionTerm>Visable To Tenant Field Name</DescriptionTerm>
                                <DescriptionDetails>{queryDetail.visible_to_tenant_field_name}</DescriptionDetails>
                                <DescriptionTerm>Preview API URL</DescriptionTerm>
                                <DescriptionDetails><Code>https://graph.microsoft.com/v1.0/sites/[SITE ID]/lists/[LIST ID]/items?$filter=fields/{queryDetail.agreement_field_name} eq '[AGREEMENT REFERENCE]' and fields/{queryDetail.document_type_field_name} eq '[LOOKUP ID/LOOKUP VALUE]' and fields/{queryDetail.visible_to_tenant_field_name} eq 1&$expand=fields</Code></DescriptionDetails>
                            </DescriptionList>
                        </DialogBody>
                        <DialogActions>
                        <Button plain onClick={() => setQueryDetailDialog(false)}>
                            Close
                        </Button>
                        </DialogActions>
                    </Dialog>
                )}

                {termsToAccept && (
                    <Dialog open={termsDialog} onClose={()=>{setTermsDialog(false)}} size='3xl'>
                        <DialogTitle>{termsToAccept.description}</DialogTitle>
                        <DialogDescription>
                            Please read and accept the following terms and conditions to enable this integration.
                        </DialogDescription>
                        <DialogBody>
                            <div ref={termsRef} onScroll={handleScroll} className={'h-[300px] overflow-auto border rounded-md p-2'}>
                                <Markdown>{termsToAccept.terms}</Markdown>
                            </div>

                        </DialogBody>
                        <CheckboxField className={'mt-2 p-2'}>
                            <Checkbox disabled={!isScrolledToBottom}  style={{ cursor: !isScrolledToBottom ? 'not-allowed' : 'pointer' }} name="accept-terms" value="" onChange={(checked) => setTermsCheckBoxAccepted(checked)} />
                            <Label>I accept the Terms & Conditions detailed above</Label>
                            <Description>By accepting these Terms & Conditions, you are confirming you have read and understood the above and accept all aspects of these Terms & Conditions.</Description>
                        </CheckboxField>
                        <DialogActions>
                        <Button plain onClick={() => setTermsDialog(false)}>
                            Cancel
                        </Button>
                        <Button color='blue' style={{ cursor: !termsCheckBoxAccepted ? 'not-allowed' : 'pointer' }}  disabled={!termsCheckBoxAccepted}  onClick={() => {acceptTerms(termsToAccept.name)}}>Accept Terms & Conditions</Button>

                        </DialogActions>
                    </Dialog>
                )}
                
                </>        
            )}                
            </AdminSidebar>
            {/* {savebutton && (
            <div className="fixed bottom-5 right-5">
                <button onClick={() => {UpdateIntegrations()}} className="mb-4 rounded-md bg-blue-700 px-3 py-2 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">Save Changes</button>
            </div>
            )} */}
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div> 
    );
}

