import {react, useState, useEffect, useContext} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import getAPI from '../functions/API/getAPI.js'
import GlobalContext from "../context/GlobalContext.js";
import { DevicePhoneMobileIcon, FolderIcon, PhoneIcon, UserIcon, ClipboardIcon, BriefcaseIcon, EyeIcon, CalendarIcon, UserCircleIcon, CurrencyPoundIcon, CreditCardIcon, ArrowUpIcon, ArrowDownIcon, ArrowRightIcon, UserPlusIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import moment from 'moment';
import Loader from '../components/loader.js';

export default function Reports() {
    
    const [startDate, setStartDate] = useState(moment().subtract(7, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const {settings, theme, themeCSS} = useContext(GlobalContext)
    const [statistics, setStatistics] = useState();
    const [ loadingspinner, setLoadingSpinner ] = useState(false);
    const [previousRange, setPreviousRange] = useState();
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    async function GetAPIData(sdate, edate){
        setLoadingSpinner(true);

        let startdate;
        let enddate;

        if(sdate && edate){
            startdate = sdate;
            enddate = edate;
            setStartDate(sdate); 
            setEndDate(edate);
        } else { 
            startdate = startDate;
            enddate = endDate;
        }

        let statsResponse = await getAPI('admin/reporting?startdate='+startdate+'&enddate='+enddate);
        let status = statsResponse.response;
        let stats = statsResponse.data.stats;
        let previousPeriod = statsResponse.data.previousPeriod;
        setPreviousRange(statsResponse.data.previousRange);

        if(status.ok){
            if(!stats){

            }
            setStatistics([
                { id: 1, name: 'New user registrations', stat: stats.signups ? stats.signups : 0, prev: previousPeriod.signups ? previousPeriod.signups : 0, icon: UserPlusIcon },
                { id: 2, name: 'Active Users', stat: stats.unique_sessions ? stats.unique_sessions : 0, prev: previousPeriod.unique_sessions ? previousPeriod.unique_sessions : 0, icon: UserIcon },
                { id: 3, name: 'Viewed Transactions', stat: stats.unique_transactions ? stats.unique_transactions : 0, prev: previousPeriod.unique_transactions ? previousPeriod.unique_transactions : 0, icon: ClipboardIcon },
                { id: 4, name: 'Viewed Repairs', stat: stats.unique_repairs ? stats.unique_repairs : 0, prev: previousPeriod.unique_repairs ? previousPeriod.unique_repairs : 0, icon: BriefcaseIcon },
                { id: 5, name: 'Viewed Requests', stat: stats.unique_requests ? stats.unique_requests : 0, prev: previousPeriod.unique_requests ? previousPeriod.unique_requests : 0, icon: EyeIcon },
                { id: 6, name: 'Viewed Appointments', stat: stats.unique_appointments ? stats.unique_appointments : 0, prev: previousPeriod.unique_appointments ? previousPeriod.unique_appointments : 0, icon: CalendarIcon },
                { id: 7, name: 'Viewed Profile', stat: stats.unique_profile ? stats.unique_profile : 0, prev: previousPeriod.unique_profile ? previousPeriod.unique_profile : 0, icon: UserCircleIcon },
                { id: 8, name: 'Viewed Documents', stat: stats.unique_documents ? stats.unique_documents : 0, prev: previousPeriod.unique_documents ? previousPeriod.unique_documents : 0, icon: DocumentTextIcon },
                { id: 9, name: 'Clicked Make Payment', stat: stats.action_payments ? stats.action_payments : 0, prev: previousPeriod.action_payments ? previousPeriod.action_payments : 0, icon: CreditCardIcon },
                { id: 10, name: 'Clicked Create Direct Debit', stat: stats.action_directdebits ? stats.action_directdebits : 0, prev: previousPeriod.action_directdebits ? previousPeriod.action_directdebits : 0, icon: CurrencyPoundIcon },
                { id: 11, name: 'Raised Repair', stat: stats.action_createrepair ? stats.action_createrepair : 0, prev: previousPeriod.action_createrepair ? previousPeriod.action_createrepair : 0, icon: BriefcaseIcon },
                { id: 12, name: 'Added Contact Detail', stat: stats.action_addcontact ? stats.action_addcontact : 0, prev: previousPeriod.action_addcontact ? previousPeriod.action_addcontact : 0, icon: PhoneIcon },
                { id: 13, name: 'Updated Contact Detail', stat: stats.action_updatecontact ? stats.action_updatecontact : 0, prev: previousPeriod.action_updatecontact ? previousPeriod.action_updatecontact : 0, icon: DevicePhoneMobileIcon },
                { id: 14, name: 'Deleted Account', stat: stats.action_deleteaccount ? stats.action_deleteaccount : 0, prev: previousPeriod.action_deleteaccount ? previousPeriod.action_deleteaccount : 0, icon: FolderIcon }
                ]
            )
        }
        setLoadingSpinner(false);
        setIsLoaded(true);
    }

    useEffect(() => {
        GetAPIData(null, null);
    }, []);
    
    return (
        <>
            <AdminSidebar>
            {loading ? (    
                <Loader style='report' setLoadingState={setLoading} isLoaded={isLoaded} /> 
            ) : (
                statistics && settings && (
                    <div className="dark:bg-white">
                        <div className='grid grid-cols-12'>
                            <h3 className="text-2xl text-left leading-6 text-gray-900 col-span-3 w-full">Usage Statistics</h3>
                            <div className='grid grid-cols-1 md:grid-cols-4 gap-2 col-span-12'>
                                <div>
                                    <p><br/></p>
                                    <span className="isolate inline-flex rounded-md border-2-sm h-10">
                                        <button
                                            onClick={() => { GetAPIData(moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")) }}
                                            type="button"
                                            className="relative inline-flex items-center border border-gray-300 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"
                                        >
                                            Today
                                        </button>
                                        <button
                                            onClick={() => { GetAPIData(moment().subtract(7, 'day').format('YYYY-MM-DD'), moment().format("YYYY-MM-DD")) }}
                                            type="button"
                                            className="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"
                                        >
                                            7 Days
                                        </button>
                                        <button
                                            onClick={() => { GetAPIData(moment().subtract(30, 'day').format('YYYY-MM-DD'), moment().format("YYYY-MM-DD")) }}
                                            type="button"
                                            className="relative -ml-px inline-flex items-center border border-gray-300 rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"
                                        >
                                            30 Days
                                        </button>
                                    </span>
                                </div>
                                <div>
                                    <p className='text-xs text-left dark:text-black'>Start Date</p>
                                    <input 
                                        type="date"
                                        id="startdate"
                                        name="startdate"
                                        value={startDate}
                                        onChange={(e) => { setStartDate(e.target.value) }}
                                        className={"w-full mt-2 h-10 rounded-md border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"}
                                    />
                                </div>
                                <div>
                                    <p className='text-xs text-left dark:text-black'>End Date</p>
                                    <input 
                                        type="date"
                                        id="enddate"
                                        name="enddate"
                                        value={endDate}
                                        onChange={(e) => { setEndDate(e.target.value) }}
                                        className={"w-full mt-2 h-10 border rounded-md border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"}
                                    />
                                </div>
                                <div>
                                    <p className='text-xs text-left'><br/></p>
                                    <button onClick={() => {GetAPIData()}} className={"w-full mt-2 h-10 rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10" + (themeCSS.inputCSS)}>
                                        {loadingspinner && (
                                            <div className='flex justify-center'>
                                                <svg aria-hidden="true" className="mr-2 w-8 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                </svg>
                                            </div>
                                        )}
                                        {!loadingspinner && (
                                            <>
                                            Apply
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <dl className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 pb-4">
                                {statistics.map((item) => (
                                <div key={item.id} className="relative overflow-hidden rounded-lg bg-gray-50 ring-1 ring-gray-900/5 px-4 pb-5 pt-5 sm:px-6 sm:pt-6">
                                    <dt>
                                        <div className="absolute rounded-md p-3" style={{backgroundColor: settings.primary_hex_colour}}>
                                            <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </div>
                                        <p className="ml-16 truncate text-sm font-medium text-left text-gray-700">{item.name}</p>
                                    </dt>
                                    <dd className="ml-16 flex items-baseline ">
                                        <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                                        <p className={'ml-2 flex items-baseline text-sm font-semibold'}>
                                            
                                            {item.stat >= item.prev && (
                                                <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                                            )}
                                            {item.stat <= item.prev && (
                                                <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                                            )}
                                            {/* )} */}
                                            <p className={item.stat >= item.prev ? 'text-green-600' : 'text-red-600'}>{Math.abs(item.stat - item.prev)}</p>
                                            <p className="text-xs font-light pl-2 italic dark:text-black">Vs. {previousRange}</p>
                                        </p>
                                    </dd>
                                </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                )
            )}
            </AdminSidebar>
        </> 
    );
}

