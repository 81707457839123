import apiCall from "./apiCall";
export default async function getAPI(URLEND, PUBLIC, BINARYDATA) {
    let method = "GET";
    let URL = ""
    if (PUBLIC) {
        URL = process.env.REACT_APP_API_URL+'/api/'+URLEND;
    } else {
        URL = process.env.REACT_APP_API_URL+'/api/'+process.env.REACT_APP_API_VERSION+'/'+URLEND;

    }

    if (BINARYDATA) {
        method = "GETBINARY";
    }
    
    const BODY = {}
    const data = await apiCall(method, URL, BODY);
    return data;
};