import { useState, useEffect } from 'react';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Loader from './loader.js';

export default function CategoriesTable({setForm, refresh, setDataToEdit}) {
    
    const [reload, setReload] = useState(false);
    const [activeFilter, setActiveFilter] = useState("Filter: Active");
    const [tableData, setTableData] = useState();

    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    async function GetAPIData() {
        let CategoryResponse = await getAPI('category?active=1');
        let workingTableData = {
            title: 'Categories',
            subheading: 'Repair categories group different repair definitions together. This is the first selection a customer makes when raising a repair.',
            buttonText: 'Add Category',
            buttonFunction: setFormState,
            tableHeadings: [
                {value: 'Category Name'},
                {value: 'Friendly Name (Shown to the customer)'},
                {value: ''},
            ],
            rows: []
        }

        if(activeFilter === "Filter: Active"){
            CategoryResponse.data.forEach(element => {
                workingTableData.rows.push ({data:[
                    {type: 'text', value: element.name},
                    {type: 'text', value: element.friendlyname},
                    {
                        "type": "button",
                        "value": "Edit",
                        "action": "function",
                        "buttontype": "info",
                        "function": setEditDataState,
                        'functionparam': element         
                    },
                    {
                        "type": "button",
                        "value": activeFilter == "Filter: Active" ? "Make inactive" : "Make active",
                        "action": "function",
                        "buttontype": "warning",
                        "function": updateCategory,
                        'functionparam': element.id         
                    }
                ]})
            });
            setTableData(workingTableData);
            setIsLoaded(true);
        }
        if(activeFilter === "Filter: Inactive"){
            let CategoryResponse = await getAPI('category?active=0');
            CategoryResponse.data.forEach(element => {
                workingTableData.rows.push ({data:[
                    {type: 'text', value: element.name},
                    {type: 'text', value: element.friendlyname},
                    {
                        "type": "button",
                        "value": "Edit",
                        "action": "function",
                        "buttontype": "info",
                        "function": setEditDataState,
                        'functionparam': element         
                    },
                    {
                        "type": "button",
                        "value": activeFilter == "Filter: Active" ? "Make inactive" : "Make active",
                        "action": "function",
                        "buttontype": "warning",
                        "function": updateCategory,
                        'functionparam': element.id      
                    }
                ]})
            });
            setTableData(workingTableData);
            setIsLoaded(true);
        }
    }

    function setFormState(){
        setDataToEdit(null);
        setForm(true);
    }

    function setEditDataState(data){
        setDataToEdit(data);
        setForm(true);
    }

    async function updateCategory(id) {
        let active = activeFilter == "Filter: Active" ? 0 : 1; 
        const body = {id: id, active: active}

        const UpdateCategoryResponse = await postAPI('category/update', body);

        if(UpdateCategoryResponse.response.ok){
            toast.success('Category updated successfully.');
        }else{
            toast.error('Sorry an error occurred. Try again later.');
        }
        setReload(!reload)
    }

    useEffect(() => {
        GetAPIData();
    }, [activeFilter, reload, refresh]);
    
    return (
        loading ? (
            <Loader style='table' setLoadingState={setLoading} isLoaded={isLoaded}/>
        ) : (
            <div>
                {tableData && (
                    <div>
                        <div className="sm:flex sm:items-center">
                            {tableData && (
                            <div className="sm:flex-auto">
                                <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">{tableData.title}</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                {tableData.subheading}
                                </p>
                            </div>
                            )}
                        {tableData.buttonText && tableData.buttonFunction && (
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <button onClick={tableData.buttonFunction} type="button" className="block rounded-md bg-blue-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                    {tableData.buttonText}
                                </button>
                            </div>
                        )}
                        </div>
                        
                        <Menu as="div" className="mt-6 relative inline-block text-left justify-end flex">
                            <div>
                                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                {activeFilter}
                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        <button type="button" onClick={() => {setActiveFilter("Filter: Active")}} className='ml-4 text-gray-700 block p-2'>
                                            Active
                                        </button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <button type="button" onClick={() => {setActiveFilter("Filter: Inactive")}} className='ml-4 text-gray-700 block p-2'>
                                            In-Active
                                        </button>
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Menu>

                        <div className="flow-root">
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full py-2 align-middle">
                                    <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                        {tableData && (
                                            tableData.tableHeadings.map((heading) => (
                                            <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            {heading.value}
                                            </th>
                                            ))
                                        )}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {tableData && (
                                        tableData.rows.map((row) => (
                                            <tr key={row.id}>
                                            {row.data.map((data) => (
                                                <>
                                                    {data.type == 'text' && (
                                                    <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                        <div className="text-gray-900">                                               
                                                            {data.value}
                                                        </div>

                                                    </td>
                                                    )}
                                                    {data.type == 'button' && (
                                                    <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                        <button onClick={()=>{data.function(data.functionparam)}} type="button" className={`${data.buttontype === 'info' ? 'bg-gray-600 hover:bg-gray-500' : 'bg-red-600 hover:bg-red-500'} rounded px-3 py-1 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700`}>
                                                            {data.value}
                                                        </button>
                                                    </td>
                                                    )}
                                                </>
                                            ))}
                                            </tr>
                                        ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                )} 
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        )
    );
}