export default async function apiCall(METHOD, URL, BODY) {
    const version = '2.0.0';

    if(METHOD == "GETBINARY"){
        const APITOKEN = localStorage.getItem("apiToken");
        const response = await 
            fetch( URL, { 
                method: 'GET', 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Client-Version': version,
                    'X-Client-Platform': 'web',
                    Authorization: 'Bearer '+ APITOKEN
                }
            });

        if(response.status == 401){
            localStorage.clear();
            window.location.href = '/login' 
        }
        if(response.status == 203 && response.message == "Extended verification required"){
            if(window.location.pathname != "/extendedverification"){
                window.location.href = '/extendedverification'
            }
        }
        return {response};
        
    } else if(METHOD != "GET"){
        const APITOKEN = localStorage.getItem("apiToken");
        const response = await 
            fetch( URL, { 
                method: METHOD, 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Client-Version': version,
                    'X-Client-Platform': 'web',
                    Authorization: 'Bearer '+ APITOKEN
                    },
                    body: JSON.stringify(
                        BODY
                    )
            });
        const data = await response.json();

        if(response.status == 401){
            localStorage.clear();
            window.location.href = '/login' 
        }
        if(response.status == 203 && data.message == "Extended verification required"){
            if(window.location.pathname != "/extendedverification"){
                window.location.href = '/extendedverification'
            }
        }
        return {data, response};
    } else {
        const APITOKEN = localStorage.getItem("apiToken");
        const response = await 
            fetch( URL, { 
                method: METHOD, 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Client-Version': version,
                    'X-Client-Platform': 'web',
                    Authorization: 'Bearer '+ APITOKEN
                    }
            });
        const data = await response.json();
        if(response.status == 401){
            localStorage.clear();
            window.location.href = '/login'
        }
        if(response.status == 203 && data.message == "Extended verification required"){
            if(window.location.pathname != "/extendedverification"){
                window.location.href = '/extendedverification'
            }
        }
        return {data, response};
    }
};