import {react, useState, useEffect, useContext} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewContactMethodForm from '../forms/newContactMethod.js';
import DeleteModal from '../modals/delete.js';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import GlobalContext from "../context/GlobalContext.js";
import SlideOverRight from '../components/slideOverRight.js';
import Loader from '../components/loader.js'
import {Heading, Subheading} from '../components/heading'
import {Button} from '../components/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table.jsx'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/dialog'
import { Field, Label } from '../components/fieldset'
import { Input } from '../components/input'
import { Listbox, ListboxLabel, ListboxOption } from '../components/listbox'
import { Strong, Text, TextLink, Code } from '../components/text'
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../components/description-list'
import { Badge } from '../components/badge'
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/dropdown'
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../components/alert'

export default function CredentialManager() {
    const {settings} = useContext(GlobalContext)
    let [isOpen, setIsOpen] = useState(false)
    let [selectedCredentialType, setSelectedCredentialType] = useState('')
    let [credentials, setCredentials] = useState([])
    let [entraCredentials, setEntraCredentials] = useState({})
    let [editedCredential, setEditedCredential] = useState({})
    let [editIsOpen, setEditIsOpen] = useState(false)
    let [deleteIsOpen, setDeleteIsOpen] = useState(false)
    let [credentialToDelete, setCredentialToDelete] = useState({})

    async function GetCredentials(){
        const CredentialsResponse = await getAPI('admin/entra/registrations');
        if (CredentialsResponse.response.ok) {
            setCredentials(CredentialsResponse.data)
        } else {
            toast.error('Unable to get available credentials');
        }
    }

    async function SaveCredential(){
        if(entraCredentials.name === '' || entraCredentials.tenant_id === '' || entraCredentials.client_id === '' || entraCredentials.secret === ''){
            toast.error('Please complete all fields');
            return;
        }

        const CredentialResponse = await postAPI('admin/entra/registration', entraCredentials)
        if (CredentialResponse.response.ok) {
            setIsOpen(false);
            setSelectedCredentialType('');
            setEntraCredentials({});
            GetCredentials();
            toast.success('Credential created successfully.');
        } else {
            toast.error('Something went wrong, please try again');
        }
    }

    function editCredential(credential){
        setEditedCredential({...credential, name: credential.name, id: credential.id})
        setEditIsOpen(true)
    }

    async function UpdateCredential(){
        if(editedCredential.id === ''){
            toast.error('No change detected');
            return;
        }

        const CredentialResponse = await postAPI('admin/entra/registration/update', editedCredential)
        if (CredentialResponse.response.ok) {
            setEditIsOpen(false);
            setEditedCredential({});
            GetCredentials();
            toast.success('Credential updated successfully.');
        } else {
            toast.error('Something went wrong, please try again');
        }
    }

    async function DeleteCredential(){
        if(credentialToDelete.id === ''){
            toast.error('No id found');
            return;
        }
        const CredentialResponse = await postAPI('admin/entra/registration/delete/'+credentialToDelete.id)
        if(CredentialResponse.response.status === 400){
            setDeleteIsOpen(false);
            toast.error('Credential currently in use.');
            return;
        }

        if (CredentialResponse.response.ok) {
            setDeleteIsOpen(false);
            setCredentialToDelete({});
            GetCredentials();
            toast.success('Credential deleted successfully.');
        } else {
            toast.error('Something went wrong, please try again');
        }
    }

    function CredsTable() {
        return (
            <Table>
                <TableHead>
                <TableRow>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Type</TableHeader>
                    {/* <TableHeader>status</TableHeader> */}
                    <TableHeader className="relative w-12">
                        <span className="sr-only">Actions</span>
                    </TableHeader>
                </TableRow>
                </TableHead>
                <TableBody>
                    {credentials && credentials.map((credential, index) => (
                    <TableRow key={index}>
                        <TableCell className="font-medium">{credential.name}</TableCell>
                        <TableCell><Code>Microsoft Entra ID</Code></TableCell>
                        {/* <TableCell><Badge color="green">In use</Badge></TableCell> */}
                        <TableCell>
                            <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                <Dropdown>
                                <DropdownButton plain aria-label="More options">
                                    <EllipsisHorizontalIcon />
                                </DropdownButton>
                                <DropdownMenu anchor="bottom end">
                                    <DropdownItem onClick={()=>{editCredential(credential)}}>Edit</DropdownItem>
                                    <DropdownItem onClick={()=>{setCredentialToDelete(credential), setDeleteIsOpen(true)}}>Delete</DropdownItem>
                                </DropdownMenu>
                                </Dropdown>
                            </div>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    useEffect(() => {
        GetCredentials()
    }, [])  

    return (
        <>
            <AdminSidebar>
                <div className="flex w-full flex-wrap items-end justify-between gap-4 border-zinc-950/10 pb-6 dark:border-white/10">
                    <div>
                        <Heading>Credential Manager</Heading>
                        <Text>
                            Credential Manager is designed to allow you to manager all of your integration credentials in one place
                        </Text>
                    </div>
                    <div className="flex gap-4">
                        <Button color='blue' onClick={() => setIsOpen(true)}>New Credential</Button>
                    </div>
                </div>
                
                <CredsTable/>
                
                <Dialog open={isOpen} onClose={(()=>{setIsOpen(false), setSelectedCredentialType('')})}>
                    <DialogTitle>New Credential</DialogTitle>
                    <DialogDescription>
                        This credential will be used to authenticate with the selected integration. Once a credential has been created, it can be associated with a specific integration.
                    </DialogDescription>
                    <DialogBody>
                    <Field>
                        <Field>
                            <Field>
                                <Label>Credential Name</Label>
                                <Input name="credential-name" onChange={(e) => { setEntraCredentials({ ...entraCredentials, name: e.target.value }) }} />
                            </Field>
                            <Label>Integration Type</Label>
                            <Listbox name="status" defaultValue="active">
                                <ListboxOption onClick={()=>{setSelectedCredentialType('microsoft-entra')}} value="microsoft-entra">
                                <ListboxLabel>Microsoft Entra ID</ListboxLabel>
                                </ListboxOption>
                            </Listbox>
                            </Field>
                        </Field>
                        {selectedCredentialType === 'microsoft-entra' && (
                            <>
                                <Field>
                                    <Label>Tenant ID</Label>
                                    <Input autoComplete="new-tenantid" name="tenant-id" onChange={(e) => { setEntraCredentials({ ...entraCredentials, tenant_id: e.target.value }) }}/>
                                </Field>
                                <Field>
                                    <Label>Client ID</Label>
                                    <Input autoComplete="new-clientid" name="client-id" onChange={(e) => { setEntraCredentials({ ...entraCredentials, client_id: e.target.value }) }}/>
                                </Field>
                                <Field>
                                    <Label>Client Secret</Label>
                                    <Input autoComplete="new-password" type="password" name="secret" onChange={(e) => { setEntraCredentials({ ...entraCredentials, secret: e.target.value }) }}/>
                                </Field>
                            </>
                        )}
                    </DialogBody>
                    <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button color='blue' onClick={() => SaveCredential()}>Add</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={editIsOpen} onClose={()=>{setEditIsOpen(false), setEditedCredential({})}}>
                    <DialogTitle>Edit Credential</DialogTitle>
                    <DialogDescription>
                        You can edit any element of the credential here. Please note that only fields with new values will be updated.
                    </DialogDescription>
                    <DialogBody>
                    <Field>
                        <Field>
                            <Field>
                                <Label>Credential Name</Label>
                                <Input name="credential-name" value={editedCredential.name} onChange={(e) => { setEditedCredential({ ...editedCredential, name: e.target.value }) }} />
                            </Field>
                            </Field>
                        </Field>
                        <Field>
                            <Label>Tenant ID</Label>
                            <Input autoComplete="new-tenantid" name="tenant-id" onChange={(e) => { setEditedCredential({ ...editedCredential, tenant_id: e.target.value }) }}/>
                        </Field>
                        <Field>
                            <Label>Client ID</Label>
                            <Input autoComplete="new-clientid" name="client-id" onChange={(e) => { setEditedCredential({ ...editedCredential, client_id: e.target.value }) }}/>
                        </Field>
                        <Field>
                            <Label>Client Secret</Label>
                            <Input autoComplete="new-password" type="password" name="secret" onChange={(e) => { setEditedCredential({ ...editedCredential, secret: e.target.value }) }}/>
                        </Field>
                    </DialogBody>
                    <DialogActions>
                    <Button plain onClick={() => setEditIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button color='blue' onClick={() => UpdateCredential()}>Save</Button>
                    </DialogActions>
                </Dialog>

                <Alert open={deleteIsOpen} onClose={setDeleteIsOpen}>
                    <AlertTitle>Are you sure you want to delete this credential?</AlertTitle>
                    <AlertDescription>
                        You can only delete a credential if its not associated with any integrations. This action cannot be undone.
                    </AlertDescription>
                    <AlertDescription>
                        Credential to delete: <Strong>{credentialToDelete.name}</Strong>
                    </AlertDescription>
                    <AlertActions>
                    <Button plain onClick={() => setDeleteIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button color='red' onClick={() => DeleteCredential(credentialToDelete)}>Delete</Button>
                    </AlertActions>
                </Alert>
            </AdminSidebar>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );   
}

