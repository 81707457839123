'use client'
import { Fragment, useEffect, useState, useContext } from 'react'
import getAPI from '../../functions/API/getAPI.js'
import postAPI from '../../functions/API/postAPI.js'
import GlobalContext from "../../context/GlobalContext.js";
import { Avatar } from '../../components/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../../components/dropdown'
import { Navbar, NavbarItem, NavbarLabel, NavbarSection, NavbarSpacer } from '../../components/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../../components/sidebar'
import { SidebarLayout } from '../../components/sidebar-layout'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/16/solid'
import {
  Cog6ToothIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TicketIcon,
  ChartBarSquareIcon,
  UsersIcon,
  XMarkIcon,
  CogIcon,
  EnvelopeIcon,
  WrenchScrewdriverIcon,
  BoltIcon
} from '@heroicons/react/20/solid'

export default function AdminSidebar({children}) {
  const {settings} = useContext(GlobalContext)
  let [userDetails, setUserDetails] = useState({})

  const topnavigation = [
    { id: 1, name: 'Dashboard', href: '/admin', icon: HomeIcon, current: window.location.pathname == '/admin' ? true : false, breadcrumb: [{name: 'Dashboard'}] },
    { id: 2, name: 'Reports', href: '/reports', icon: ChartBarSquareIcon, current: window.location.pathname == '/reports' ? true : false, breadcrumb: [{name: 'Reports'}] },
  ]

  const navigation = [
    { id: 3, name: 'Message Center', href: '/message-center', icon: EnvelopeIcon, current: window.location.pathname == '/message-center' ? true : false, breadcrumb: [{name: 'Message Center'}]  },
    { id: 4, name: 'People', href: '#', icon: UsersIcon, current: window.location.pathname == '/customers' || window.location.pathname == '/adminusers', breadcrumb: [{name: 'People'}, {name: window.location.pathname == '/customers' && "Customers" || window.location.pathname == '/adminusers' && "Administrators"}],
      children: [
        { name: 'Customers', href: '/customers', current: window.location.pathname == '/customers' },
        { name: 'Administrators', href: '/adminusers', current: window.location.pathname == '/adminusers' }
      ], 
    },
    { id: 5, name: 'Repairs', href: '#', icon: WrenchScrewdriverIcon, current: window.location.pathname == '/categories' || window.location.pathname == '/repairdefinitions' || window.location.pathname == '/repair-groups', breadcrumb: [{name: 'Repairs Configuration'}, {name: window.location.pathname == '/categories' && "Categories" || window.location.pathname == '/repairdefinitions' && "Definitions" || window.location.pathname == '/repair-groups' && "Groups"}],
      children: [
        { name: 'Categories', href: '/categories', current: window.location.pathname == '/categories' },
        { name: 'Definitions', href: '/repairdefinitions', current: window.location.pathname == '/repairdefinitions' },
        { name: 'Groups', href: '/repair-groups', current: window.location.pathname == '/repair-groups' }
      ], 
    },
    { id: 6, name: 'Configuration', href: '#', icon: CogIcon, 
      current: 
        window.location.pathname == '/contactmethods' ||
        window.location.pathname == '/requesttypes' ||
        window.location.pathname == '/settings' ||
        window.location.pathname == '/advancedsettings',
      breadcrumb: 
        [
          {name: 'Configuration'}, 
          {name: 
            window.location.pathname == '/contactmethods' && "Contact Methods" ||
            window.location.pathname == '/requesttypes' && "Request Types" ||
            window.location.pathname == '/settings' && "Settings" ||
            window.location.pathname == '/advancedsettings' && "Advance Settings" }],
      children: [
        { name: 'Contact Methods', href: '/contactmethods', current: window.location.pathname == '/contactmethods' },
        { name: 'Request Types', href: '/requesttypes', current: window.location.pathname == '/requesttypes' },
        { name: 'Settings', href: '/settings', current: window.location.pathname == '/settings' },
        { name: 'Advanced', href: '/advancedsettings', current: window.location.pathname == '/advancedsettings' },
      ], 
    },
    { id: 6, name: 'Integrations', href: '#', icon: BoltIcon, 
      current: 
        window.location.pathname == '/credential-manager' ||
        window.location.pathname == '/integration-manager',
      breadcrumb: 
        [
          {name: 'Configuration'}, 
          {name: 
            window.location.pathname == '/credential-manager' && "Credential Manager" ||
            window.location.pathname == '/integration-manager' && "Integration Manager" }],
      children: [
        { name: 'Credential Manager', href: '/credential-manager', current: window.location.pathname == '/credential-manager' },
        { name: 'Integration Manager', href: '/integration-manager', current: window.location.pathname == '/integration-manager' }, 
      ], 
    },
  ]
  
  const shortcuts = [
    { id: 1, name: 'Repairs Groups', href: '/repair-groups', initial: 'R', navId: 5, subNavId: 53 },
    { id: 2, name: 'Customers', href: '/customers', initial: 'C', navId: 3, subNavId: 31 },
    { id: 3, name: 'Settings', href: '/settings', initial: 'S', navId: 6, subNavId: 65 },
  ]

  async function GetAPIData() {
    const UserDetailsResponse = await getAPI('userdetails');
    if (UserDetailsResponse.response.ok) {
      setUserDetails(UserDetailsResponse.data)
    }
  }

  async function logout(){
      const body = {"":""}
      let destroy = await postAPI('token/destroy', body);
      if (destroy.response.ok) {
          localStorage.clear();
          window.location.href = '/'
      }
  }

  function Logo() {
      if (settings.logo_url) {
          return (
              <img 
              src={settings.logo_url}
              alt="logo"
              width="180"
              className="mx-"
              />
          );
      } else {
          return (
              <h2 className="text-3xl font-semibold text-gray-800 dark:text-white">{settings.company_name}</h2>
          );
      }
  }

  function LogoComponent(){
    return(
      <div className="ml-2.5">
        <Logo/>
        {settings && (
          <>
            <p className="text-xs text-gray-400 pt-2" >V{settings.version}
            {!!settings.test_system && (
              <span className="inline-flex items-center ml-2 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                Test System
              </span>
            )}
            </p>

          </>
        )}
      </div>
    )
  }

  useEffect(() => {
    GetAPIData();
  }, [])

  return (
    <SidebarLayout
      sidebar={
        <Sidebar>

          <SidebarHeader>
          <LogoComponent/>
            <SidebarSection className="mt-2">
              {topnavigation.map((item) => (
                item.current ? (
                <SidebarItem current href={item.href}>
                  <item.icon />
                  <SidebarLabel>{item.name}</SidebarLabel>
                </SidebarItem>
                ):(
                  <SidebarItem href={item.href}>
                    <item.icon />
                    <SidebarLabel>{item.name}</SidebarLabel>
                  </SidebarItem>
                )
              ))}
            </SidebarSection>
          </SidebarHeader>

          <SidebarBody>
            <SidebarSection>
              {navigation.map((item) => (
                item.current ? (
                  item.children ? (
                    <Dropdown>
                      <DropdownButton as={NavbarItem}>
                        <item.icon />
                        <NavbarLabel>{item.name}</NavbarLabel>
                        <ChevronDownIcon />
                      </DropdownButton>
                      <DropdownMenu className="min-w-64" anchor="bottom start">
                      {item.children.map((subItem) => (
                        <DropdownItem href={subItem.href}>
                          <DropdownLabel>{subItem.name}</DropdownLabel>
                        </DropdownItem>
                      ))}
                      </DropdownMenu>
                    </Dropdown>
                  ):(
                    <SidebarItem current href={item.href}>
                      <item.icon />
                      <SidebarLabel>{item.name}</SidebarLabel>
                    </SidebarItem>
                  )
                ):(
                  item.children ? (
                    <Dropdown>
                      <DropdownButton as={NavbarItem}>
                        <item.icon />
                        <NavbarLabel>{item.name}</NavbarLabel>
                        <ChevronDownIcon />
                      </DropdownButton>
                      <DropdownMenu className="min-w-64" anchor="bottom start">
                      {item.children.map((subItem) => (
                        <DropdownItem href={subItem.href}>
                          <DropdownLabel>{subItem.name}</DropdownLabel>
                        </DropdownItem>
                      ))}
                      </DropdownMenu>
                    </Dropdown>
                  ):(
                    <SidebarItem href={item.href}>
                      <item.icon />
                      <SidebarLabel>{item.name}</SidebarLabel>
                    </SidebarItem>
                  )
                )
              ))}
            </SidebarSection>
            <SidebarSection>
              <SidebarHeading>Shortcuts</SidebarHeading>
              {shortcuts.map((shortcut) => (  
                <SidebarItem href={shortcut.href}>{shortcut.name}</SidebarItem>
              ))}
            </SidebarSection>
            <SidebarSpacer />
            <SidebarSection>
              <SidebarItem href="https://codebytwo.atlassian.net/servicedesk/customer/portal/1" target="_blank">
                <QuestionMarkCircleIcon />
                <SidebarLabel>Support</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="https://docs.tenantwise.co.uk/tenantwise-documentation" target="_blank">
                <SparklesIcon />
                <SidebarLabel>Docs</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  {/* <Avatar src="/profile-photo.jpg" className="size-10" square alt="" /> */}
                  {userDetails && (
                    <span className="min-w-0">
                      <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{userDetails.firstname} {userDetails.lastname}</span>
                      <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                        {userDetails.email}
                      </span>
                    </span>
                  )}
                  
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                {/* <DropdownItem href="/my-profile">
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/settings">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem> */}
                <DropdownDivider />
                <DropdownItem href="https://codebytwo.com/privacypolicy" target="_blank">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="https://codebytwo.atlassian.net/servicedesk/customer/portal/1" target="_blank">
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem onClick={() =>{logout()}} >
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      {children}
    </SidebarLayout>
    
  )
}