import {useState} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import 'react-toastify/dist/ReactToastify.css';
import CategoriesTable from '../components/categoriesTable.js';
import NewCategoryForm from '../forms/newCategory.js';
import SlideOverRight from '../components/slideOverRight.js';

export default function Categories() {
    const [newCategoryForm, setNewCategoryForm] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(0);
    const [editData, setEditData] = useState(null);
    
    return (
        <>
            <AdminSidebar>
                <CategoriesTable setForm={setNewCategoryForm} refresh={shouldRefresh} setDataToEdit={setEditData}/>
                <SlideOverRight visible={newCategoryForm} setVisible={setNewCategoryForm}>
                    <NewCategoryForm setVisible={setNewCategoryForm} refresh={setShouldRefresh} refreshValue={shouldRefresh} categoryData={editData}/>
                </SlideOverRight>
            </AdminSidebar>
        </>
    );
}

