import {useState, useEffect, useContext} from 'react';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import GlobalContext from "../context/GlobalContext.js";
import 'react-toastify/dist/ReactToastify.css';
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import SlideOverRight from './slideOverRight.js';
import Loader from './loader.js';
import NewDefinitionForm from '../forms/newDefinition.js'

export default function RepairDefinitionsTable({setForm, refresh}) {
    
    const [reload, setReload] = useState(false);
    const [activeFilter, setActiveFilter] = useState("Filter: Active")
    const [safetyNoticeToShow, setSafetyNoticeToShow] = useState();
    const [safetyNoticePane, setSafetyNoticePane] = useState(false);

    const [tableData, setTableData] = useState();

    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [editDefinitionSlideoutVisible, setEditDefinitionSlideoutVisible] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(0);
    const [dataToEdit, setDataToEdit] = useState({});
    const [updateRefresh, setUpdateRefresh] = useState(0);

    async function GetAPIData() {
        let DefinitionResponse = await getAPI('repairdefinitions?active=1');
        let workingTableData = {
            title: 'Repair Definitions',
            subheading: 'Repair definitions provide the customer with a logical selection of repair options.',
            buttonText: 'Add Definition',
            buttonFunction: setFormState,
            tableHeadings: [
                {value: 'Description'},
                {value: 'Category'},
                {value: 'SOR'},
                {value: 'Contractor'},
                {value: 'Safety Notice'},
                {value: ''},
                {value: ''},
            ],
            rows: []
        }

        if(activeFilter === "Filter: Active"){
            DefinitionResponse.data.forEach(element => {
                workingTableData.rows.push ({data:[
                    {type: 'text', value: element.description},
                    {type: 'text', value: element.categoryname},
                    {type: 'text', value: element.sorcode},
                    {type: 'text', value: element.contractorname},
                    {
                        "type": "button",
                        "value": "Details",
                        "action": "function",
                        "buttontype": element.safety_notice ? "info" : "none",
                        "function": showSafetyNoticePane,
                        'functionparam': element.safety_notice        
                    },
                    {
                        "type": "button",
                        "value": "Edit",
                        "action": "function",
                        "buttontype": "info",
                        "function": editDefinition,
                        'functionparam': element         
                    },
                    {
                        "type": "button",
                        "value": activeFilter == "Filter: Active" ? "Make inactive" : "Make active",
                        "action": "function",
                        "buttontype": "warning",
                        "function": updateDefinitionStatus,
                        'functionparam': element.id         
                    }
                ]})
            });
            setTableData(workingTableData);
            setIsLoaded(true);
        }
        if(activeFilter === "Filter: Inactive"){
            let DefinitionResponse = await getAPI('repairdefinitions?active=0');
            DefinitionResponse.data.forEach(element => {
                workingTableData.rows.push ({data:[
                    {type: 'text', value: element.description},
                    {type: 'text', value: element.categoryname},
                    {type: 'text', value: element.sorcode},
                    {type: 'text', value: element.contractorname},
                    {
                        "type": "button",
                        "value": "Details",
                        "action": "function",
                        "buttontype": element.safety_notice ? "info" : "none",
                        "function": showSafetyNoticePane,
                        'functionparam': element.safetynotice        
                    },
                    {
                        "type": "button",
                        "value": "Edit",
                        "action": "function",
                        "buttontype": "info",
                        "function": editDefinition,
                        'functionparam': element         
                    },
                    {
                        "type": "button",
                        "value": activeFilter == "Filter: Active" ? "Make inactive" : "Make active",
                        "action": "function",
                        "buttontype": "warning",
                        "function": updateDefinitionStatus,
                        'functionparam': element.id         
                    }
                ]})
            });
            setTableData(workingTableData);
        }
        setIsLoaded(true);
    }

    function setFormState(){
        setForm(true);
    }

    async function updateDefinitionStatus(id) {

        let active = activeFilter == "Filter: Active" ? 0 : 1; 

        const body = {id: id, active: active}
        const UpdateDefinitionStatusResponse = await postAPI('repairdefinitions/toggle', body);

        if(UpdateDefinitionStatusResponse.response.ok){
            toast.success('Definition Updated Successfully.');
        }else{
            toast.error('An error occurred. Try again later.');
        }
        setReload(!reload)
    }

    async function editDefinition(data) {
        setDataToEdit(data);
        setEditDefinitionSlideoutVisible(true);
    }

    async function showSafetyNoticePane(safetynotice){
        setSafetyNoticeToShow(safetynotice)
        setSafetyNoticePane(true);
    }

    function refreshUpdatedDefinition(){
        setUpdateRefresh(updateRefresh + 1);
    }

    useEffect(() => {
        GetAPIData();
    }, [reload, activeFilter, refresh, updateRefresh]);
    
    return (
        loading ? (
            <Loader style='table' setLoadingState={setLoading} isLoaded={isLoaded}/>
        ) : (
        <div>
            {tableData && (
                <div>
                    <div className="sm:flex sm:items-center">
                        {tableData && (
                        <div className="sm:flex-auto">
                            <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">{tableData.title}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                            {tableData.subheading}
                            </p>
                        </div>
                        )}
                    {tableData.buttonText && tableData.buttonFunction && (
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button onClick={tableData.buttonFunction} type="button" className="block rounded-md bg-blue-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                {tableData.buttonText}
                            </button>
                        </div>
                    )}
                    </div>
                    
                    <Menu as="div" className="mt-6 relative inline-block text-left justify-end flex">
                        <div>
                            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            {activeFilter}
                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    <button type="button" onClick={() => {setActiveFilter("Filter: Active")}} className='ml-4 text-gray-700 block p-2'>
                                        Active
                                    </button>
                                </Menu.Item>
                                <Menu.Item>
                                    <button type="button" onClick={() => {setActiveFilter("Filter: Inactive")}} className='ml-4 text-gray-700 block p-2'>
                                        In-Active
                                    </button>
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Menu>

                    <div className="flow-root">
                        <div className="overflow-x-auto">
                            <div className="inline-block min-w-full py-2 align-middle">
                                <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                    {tableData && (
                                        tableData.tableHeadings.map((heading) => (
                                        <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        {heading.value}
                                        </th>
                                        ))
                                    )}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {tableData && (
                                    tableData.rows.map((row) => (
                                        <tr key={row.id}>
                                        {row.data.map((data) => (
                                            <>
                                                {data.type == 'text' && (
                                                <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                    <div className="text-gray-900">                                               
                                                        {data.value}
                                                    </div>

                                                </td>
                                                )}
                                                {data.type == 'button' && (
                                                    data.buttontype != 'none' ? (
                                                        <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                            <button onClick={()=>{data.function(data.functionparam)}} type="button" className={`rounded ${data.buttontype === 'info' ? 'bg-gray-600 hover:bg-gray-500' : 'bg-red-600 hover:bg-red-500'} px-3 py-1 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700`}>
                                                                {data.value}
                                                            </button>
                                                        </td>
                                                    ):(
                                                        <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                        </td>
                                                    )
                                                )}
                                            </>
                                        ))}
                                        </tr>
                                    ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            )} 
        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <SlideOverRight visible={safetyNoticePane} setVisible={setSafetyNoticePane}>
            <div className="px-4 sm:px-6">
                <div className="flex items-start justify-between">
                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                    Safety Notice
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                    <button
                        type="button"
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setSafetyNoticePane(false)}
                    >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    </div>
                </div>
                </div>
                <div className="border-l-4 border-red-400 bg-red-50 p-4 m-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-red-700">
                            {safetyNoticeToShow}
                        </p>
                    </div>
                </div>
            </div>
            <div className="relative mt-6 flex-1 px-4 sm:px-6">
            <p className="text-sm text-gray-800">This warning has to be accepted by the user before they can proceed with the repair.</p>
            </div>
        </SlideOverRight>
        <SlideOverRight visible={editDefinitionSlideoutVisible} setVisible={setEditDefinitionSlideoutVisible}>
            <NewDefinitionForm setVisible={setEditDefinitionSlideoutVisible} refresh={refreshUpdatedDefinition} refreshValue={shouldRefresh} editModeData={dataToEdit} />
        </SlideOverRight>
        </div>
        )
    );
}

