import React, {react, useState, useEffect, useContext} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import getAPI from '../functions/API/getAPI.js'
import Loader from '../components/loader.js';
import GlobalContext from '../context/GlobalContext.js';
import { UserIcon, UsersIcon, CalendarDaysIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import Table from '../components/Table.js';
import moment from 'moment';

export default function Admin() {
    
    const [stats, setStats] = useState();
    const {settings, theme, themeCSS} = useContext(GlobalContext)
    const [tableData, setTableData] = useState();
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    async function GetAPIData(){
        const StatsResponse = await getAPI('admin/stats');
        const SubscriptionResponse = await getAPI('admin/subscription');

        let workingTableData = {
            title: 'Most Recent Signups',
            subheading: 'This table shows the most recent customer registrations.',
            tableHeadings: [
                {value: 'Firstname'},
                {value: 'Lastname'},
                {value: 'Email Address'},
            ],
            rows: []
        }

        StatsResponse.data.users.forEach(user => {
            workingTableData.rows.push ({data:[
                {type: 'text', value: user.firstname},
                {type: 'text', value: user.lastname},
                {type: 'text', value: user.email},
            ]})
        });

        if(StatsResponse.response.ok)setTableData(workingTableData);
        if(StatsResponse.response.ok)setStats(StatsResponse.data);
        if(SubscriptionResponse.response.ok)setSubscription(SubscriptionResponse.data);
        setIsLoaded(true);
    }

    let badgeText, badgeColor;
    if (subscription) {
        const renewalDate = moment(subscription.renewal);
        const currentDate = moment();
        const daysUntilRenewal = renewalDate.diff(currentDate, 'days');

        if (daysUntilRenewal < 0) {
            badgeText = 'Expired';
            badgeColor = 'red';
        } else if (daysUntilRenewal <= 30) {
            badgeText = 'Expiring Soon';
            badgeColor = 'yellow';
        } else {
            badgeText = 'Active';
            badgeColor = 'green';
        }
    } else {
        badgeText = 'No Subscription';
        badgeColor = 'gray';
    }

    useEffect(() => {
        GetAPIData();
    }, []);
    
    return (
        <AdminSidebar>
            {loading ? (
                <Loader style='table' setLoadingState={setLoading} isLoaded={isLoaded}/>
            ) : (
                stats && (
                    <div className="dark:bg-white">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                            <div>
                                {settings && stats && (
                                    <div key={"1"} className="relative overflow-hidden rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 px-4 pb-5 pt-5 sm:px-6 sm:pt-6">
                                        <dt>
                                            <div className="absolute rounded-md p-3 bg-green-500">
                                                <UserIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 truncate text-sm font-medium text-left text-gray-900">New user registrations</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline ">
                                            <p className="text-2xl font-semibold text-gray-900">{stats.newuser_count}</p>
                                            <p className='text-xs ml-2 text-gray-500 '>In the last 30 days</p>
                                        </dd>
                                    </div>
                                )}
                            </div>
                            <div>
                                {settings && stats && (
                                    <div key={"2"} className="relative overflow-hidden rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 px-4 pb-5 pt-5 sm:px-6 sm:pt-6">
                                        <dt>
                                            <div className="absolute rounded-md p-3 bg-blue-500">
                                                <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 truncate text-sm font-medium text-left text-gray-900">Total registrations</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline ">
                                            <p className="text-2xl font-semibold text-gray-900">{stats.users_count}</p>
                                            <p className='text-xs ml-2 text-gray-500 '>Since system setup</p>
                                        </dd>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-4">
                            <div className="col-span-2">
                                {tableData && (
                                    <Table tableData={tableData}/>
                                )}
                            </div>
                            {subscription && (
                            <div className="col-span-1">
                                <div className="mt-4 sm:flex-auto">
                                    <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">Subscription</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                    Below shows details about your subscription.
                                    </p>
                                </div>
                                <div className="mt-16 rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                                    <dl className="flex flex-wrap">
                                    <div className="flex-auto pl-6 pt-6">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">TenantWise</dt>
                                        <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">{subscription.plan}</dd>
                                    </div>
                                    <div className="flex-none self-end px-6 pt-4">
                                        <dd className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${badgeColor === 'red' ? 'ring-1 ring-inset ring-red-600/20 bg-red-50 text-red-700' : badgeColor === 'yellow' ? 'ring-1 ring-inset ring-yellow-600/20 bg-yellow-50 text-yellow-700' : badgeColor === 'green' ? 'ring-1 ring-inset ring-green-600/20 bg-green-50 text-green-700' : 'ring-1 ring-inset ring-gray-600/20 bg-gray-50 text-gray-700'}`}>
                                            {badgeText}
                                        </dd>                                    
                                    </div>
                                    <div className="mt-6 w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 mb-2">Addons</dt>
                                        {subscription && subscription.addons.map((addon, index) => 
                                            <div key={index} className="flex items-center">
                                                <PlusCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                                                <dd className="text-sm leading-6 text-gray-500 ml-2">{addon}</dd>
                                            </div>
                                        )}
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 mb-2 mt-4">Renewal</dt>
                                        <div className="flex items-center">
                                            <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                                            <dd className="text-sm leading-6 text-gray-500 ml-2">{moment(subscription.renewal).format('LL')}</dd>
                                        </div>
                                    </div>
                                    
                                    </dl>
                                    <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                                        <a href="mailto:admin@codebytwo.com" className="text-sm font-semibold leading-6 text-gray-900">
                                            Contact sales to upgrade or change<span aria-hidden="true">&rarr;</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                )
            )}
            </AdminSidebar>
    );
    
}

