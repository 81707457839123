import {useContext, useEffect, useState} from 'react';
import Nav from "../components/Nav.js";
import Modal from 'react-modal';
import HeaderBackground from '../components/headerbackground.js'
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import HeaderOverlay from '../components/headeroverlay.js';
import { ToastContainer, toast } from 'react-toastify';
import GlobalContext from "../context/GlobalContext.js";
import Loader from '../components/loader.js'
import moment from 'moment';

export default function Repairs() {
    
    const [repairsdata, setRepairsData] = useState();
    const [agreement, setAgreement] = useState();
    const [categoryModal, setCategoryModal] = useState(false);
    const [repairModal, setRepairModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [contactDetailModal, setContactDetailModal] = useState(false);
    const [timeSlotModal, setTimeSlotModal] = useState(false);
    const [selectedContactDetail, setSelectedContactDetail] = useState();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedRepairDefinition, setSelectedRepairDefinition] = useState();
    const [categories, setCategories] = useState(false);
    const [repairDefinitions, setRepairDefinitions] = useState();
    const [assetID, setAssetID] = useState();
    const [contactDetails, setContactDetails] = useState();
    const [loading, isLoading] = useState(true)
    const {theme, updateTheme, themeCSS, settings} = useContext(GlobalContext)
    const [repairCancelModal, setRepairCancelModal] = useState(false);
    const [repairToCancel, setRepairToCancel] = useState("");
    const [repairsAvailable, setRepairsAvailable] = useState(false);
    const [safetyModal, setSafetyModal] = useState(false);
    const [safetyNotice, setSafetyNotice] = useState();
    const [emergencyModal, setEmergencyModal] = useState(false);
    const [repairsInfo, setRepairsInfo] = useState({
      repairDescription: "",
      repairAccessNote: ""
      }
    );

    const [reload, setReload] = useState(false);

    async function GetAPIData() {
      const agreementreference = localStorage.getItem('agreement');
      const AgreementResponse = await getAPI('agreements/'+agreementreference);
      const repairsresponse = await getAPI('repairs/'+AgreementResponse.data.assetid);
      const CategoriesResponse = await getAPI('category?active=1');
      const ContactDetailsResponse = await getAPI('contactdetail');

      let modifiedCategories = [];
      
      GetCategories();
      async function GetCategories() {
        const result = await asyncSortCategories();
      }
      
      async function asyncSortCategories() {
        const promises = CategoriesResponse.data.map(async (element) => {
          const res = await getAPI(`repairdefinitions?categoryid=${element.id}&agreementreference=${AgreementResponse.data.reference}`);
          if (res.data.length > 0) {
            setRepairsAvailable(true);
            modifiedCategories.push(element);
          }
        });
      
        await Promise.all(promises);
        setRepairsData(repairsresponse.data)
        setAgreement(AgreementResponse.data);
        setCategories(modifiedCategories);
        setAssetID(AgreementResponse.data.assetid);
        setContactDetails(ContactDetailsResponse.data)
        isLoading(false)
      }
    }

    async function switchModalToRepair(){
      if (selectedCategory) {
        const RepairDefinitionsResponse = await getAPI('repairdefinitions?categoryid='+selectedCategory+'&agreementreference='+agreement.reference)
        if(RepairDefinitionsResponse.response.ok) {
          setRepairDefinitions(RepairDefinitionsResponse.data)
          setCategoryModal(false)
          setRepairModal(true);
        } else {
          toast.error('Something went wrong, please try again');
        }
      } else {
        toast.error('Please select a category');
      }
      
    }

    async function cancelRepair(){
      isLoading(true)
      let body = {'repairid': repairToCancel}
      const RepairDefinitionsResponse = await postAPI('cancelrepair', body)
      if (RepairDefinitionsResponse.response.ok) {
        GetAPIData();
        toast.success('Repair Cancelled Successfully.');
      } else {
        toast.error('Something went wrong, please try again');
      }
      setRepairCancelModal(false)
    }

    function backToCategory(){
      setRepairModal(false);
      setCategoryModal(true);
    }

    function backToRepairDefinition(){
      setContactDetailModal(false);
      setRepairModal(true);
    }

    function backToContactDetailSelection(){
      setTimeSlotModal(false);
      setContactDetailModal(true);
    }

    function backToTimeSlotSelection(){
      setDetailModal(false);
      setTimeSlotModal(true);
    }

    function switchToContactDetailModal(){
      if (selectedRepairDefinition) {
        setRepairModal(false);
        setContactDetailModal(true);

        var selecteddefinition = repairDefinitions.filter(definition => {
          return definition.id === selectedRepairDefinition;
        })
        if (selecteddefinition[0].safety_notice) {
          setSafetyNotice(selecteddefinition[0].safety_notice)
          setSafetyModal(true)
        }

      } else {
        toast.error('Please select a repair');
      }
    }

    function switchToDetailModal(){
      if (selectedTimeSlot) {
        setTimeSlotModal(false);
        setDetailModal(true);
      } else {
        toast.error('Please select a preferred time slot');
      }
    }

    function switchToTimeSlotModal(){
      if (selectedContactDetail) {
        setContactDetailModal(false);
        setTimeSlotModal(true);
      } else {
        toast.error('Please select a contact method');
      }
    }

    function handleChange(event) {
      let statename = event.target.name;
      let value = event.target.value;
      setRepairsInfo({...repairsInfo, [statename]: value});
    }

    async function createRepair(){
      if (!repairsInfo.repairDescription) {
        toast.error('Please provide a description');
        return;
      }
      isLoading(true)
      var selecteddefinition = repairDefinitions.filter(definition => {
        return definition.id === selectedRepairDefinition;
      })

      let description;
      if (repairsInfo.repairAccessNote) {
        description = '['+selecteddefinition[0].description+']: '+repairsInfo.repairDescription + ' [Prefered time slot: ' + selectedTimeSlot + ']' + ' [Access note: ' + repairsInfo.repairAccessNote + ']';
      } else {
        description = '['+selecteddefinition[0].description+']: '+repairsInfo.repairDescription + ' [Prefered time slot: ' + selectedTimeSlot + ']'
      }
 
      const body = {assetid: assetID, description: description, definitionid: selectedRepairDefinition, communicationid: selectedContactDetail, accessnote: repairsInfo.repairAccessNote, agreementreference: agreement.reference}
      const RepairResponse = await postAPI('repairs', body);
      if(RepairResponse.response.ok){
          setDetailModal(false)
          setContactDetailModal(false)
          setTimeSlotModal(false)
          setRepairModal(false)
          setCategories(false)
          setSelectedCategory('');
          setSelectedRepairDefinition('');
          setSelectedContactDetail('');
          setSelectedTimeSlot('');
          setRepairsInfo('');
          toast.success('Repair Requested Successfully.');
      }else{
          toast.error('Sorry an error occurred. Try again later.');
      }
      setReload(!reload)
      isLoading(false);
    }

    function cancelNewRepairRequest(){
      setDetailModal(false)
      setContactDetailModal(false)
      setRepairModal(false)
      setSafetyModal(false);
    }

    useEffect(() => {
      GetAPIData();
      }, [reload]);

      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
    return (<>
      {loading && (
        <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
      )}
      <div className={(themeCSS.divCSS)}>
        <Nav/>
        <HeaderBackground />
        <HeaderOverlay agreement={agreement} type={""}/>
        <div className={"min-h-screen " + (themeCSS.divCSS)}>  
          
          <div className="px-4 sm:px-6 lg:px-28 xl:px-48 mt-12 pb-24">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6">Your repairs</h1>
              <p className="mt-2 text-sm">
                Here you can view and request repairs for your property.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {agreement && agreement.type != 'Expired' && (
              <button
                type="button"
                onClick={() => {setEmergencyModal(true)}}
                className="block rounded-md bg-gray-600 px-3 py-2 text-center text-white text-sm font-semibold shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Request Repair
              </button>
            )}
            </div>
          </div>
            <div className="-mx-4 mt-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-md">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="border-gray-200 bg-gray-50 sm:rounded-tl-md py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      <div className='lg:hidden'>Repair details</div>
                      <div className='hidden lg:block'>Reference</div>
                    </th>
                    <th
                      scope="col"
                      className="border-gray-200 bg-gray-50 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Requested
                    </th>
                    <th
                      scope="col"
                      className="w-60 border-gray-200 bg-gray-50 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="border-gray-200 bg-gray-50 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                      Description
                    </th>
                    <th scope="col" className="md:rounded-tr-md bg-gray-50 relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Select</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {repairsdata && (
                            repairsdata.map((repair, index) => (
                    <tr key={index}>
                      <td
                        className={classNames(
                          index === 0 ? '' : '',
                          'relative py-4 pl-4 pr-3 text-sm sm:pl-6',
                        )}
                      >
                        <div className="font-medium flex items-center gap-2 nline-block align-center">
                          <div className=''>
                            {repair.repairid} 
                          </div>
                          <div className='lg:hidden'>
                            {repair.iscomplete ? (
                                <span className="pl-2 inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ">
                                    <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                                    <circle r={3} cx={3} cy={3} />
                                    </svg>
                                    {repair.status}
                                </span>
                            ):(
                                <span className="inline-flex items-center gap-x-1.5 rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-700">
                                    <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-orange-500">
                                    <circle r={3} cx={3} cy={3} />
                                    </svg>
                                    {repair.status}
                                </span>
                            )}
                          </div>

                        </div>
                        <div className="mt-1 flex flex-col sm:block lg:hidden">
                          <span>
                            {repair.date} 
                          </span>
                          <span className="hidden sm:inline">·</span>
                          <span>{repair.description}</span>
                        </div>
                      </td>
                      <td
                        className={'hidden px-3 py-3.5 text-sm lg:table-cell'}
                      >
                        <span>{moment(repair.date).format("DD/MM/YYYY")}</span>
                      </td>
                      <td
                        className={classNames(
                          index === 0 ? '' : '',
                          'hidden px-3 py-3.5 text-sm lg:table-cell',
                        )}
                      >
                          {repair.iscomplete ? (
                              <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ">
                                  <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                                  <circle r={3} cx={3} cy={3} />
                                  </svg>
                                  {repair.status}
                              </span>
                          ):(
                              <span className="inline-flex items-center gap-x-1.5 rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-700">
                                  <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-orange-500">
                                  <circle r={3} cx={3} cy={3} />
                                  </svg>
                                  {repair.status}
                              </span>
                          )}
                      </td>
                      <td
                        className={classNames(
                          index === 0 ? '' : '',
                          'hidden px-3 py-3.5 text-sm lg:table-cell',
                        )}
                      >
                        {repair.description}
                      </td>
                      <td
                        className={classNames(
                          index === 0 ? '' : '',
                          'inline-block align-top relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
                        )}
                      >
                        <button
                          type="button"
                          disabled={!repair.can_cancel ? true : false}
                          onClick={() => {setRepairToCancel(repair.repairid); setRepairCancelModal(true)}}
                          className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                        >
                          Cancel {repair.can_cancel}<span className="sr-only">, {repair.repairid}</span>
                        </button>
                      </td>
                    </tr>
                  )))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={categoryModal}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setCategoryModal(false)}}
        className="my-20 mx-2 pb-12 lg:mx-40 overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center h-modal h-full"
        contentLabel="Profile">
        <div>
          <div className={"rounded-2xl border overflow-auto p-10 " + (themeCSS.inputCSS)}> 
                <div className="flex flex-col items-center space-y-2">
                    <h1 className={"font-bold text-2xl w-4/6 text-center " + (themeCSS.text)}>Request a new repair</h1>
                    {repairsAvailable ? (
                      <>
                        <p className={"text-sm  text-center w-5/6 " + (themeCSS.text)}>Please select a category</p>
                        <div className="grid grid-cols-1 w-full gap-2 justify-center">
                        {categories && (
                          categories.map(function(category, index) {
                            return (
                              <button key={index} onClick={() => {setSelectedCategory(category.id)}} className={category.id === selectedCategory ? "p-4 w-full text-center border-blue-500 focus:outline-none border-4 rounded-lg " + (themeCSS.divCSS) : "p-4 w-full text-center focus:outline-none border-4 rounded-lg" + (themeCSS.divCSS)}>
                                {category.friendlyname}
                              </button>
                            )
                          })
                        )}
                        </div>
                      </>
                    ):(
                      <p className="text-lg text-red-500 font-bold text-center w-5/6">Sorry, there are no repair options currently available.</p>
                    )}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4 space-y-2 lg:space-y-0">
                    <div className="flex flex-col items-center space-y-4">
                        <button className={"btn btn-natural border-0 rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.lightButtonCSS)} onClick={() => {setCategoryModal(false)}}>Cancel</button>
                    </div>
                    {repairsAvailable ? (
                      <div className="flex flex-col items-center space-y-4">
                        <button className={"btn btn-natural capitalize rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.buttonCSS)} onClick={() => {switchModalToRepair()}}>Next</button>
                      </div>
                    ):(
                      <div className="flex flex-col items-center space-y-4">
                        <button className={"btn btn-natural capitalize rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.buttonCSS)}>Next</button>
                      </div>
                    )}
                </div>  
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={repairModal}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setRepairModal(false)}}
        className="my-20 mx-2 pb-12 lg:mx-40 overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center h-modal h-full"
        contentLabel="Profile">
        <div>
        <div className={"rounded-2xl border overflow-auto p-10 " + (themeCSS.inputCSS)}> 
                <div className="flex flex-col items-center space-y-2">
                    <h1 className={"font-bold text-2xl w-4/6 text-center "  + (themeCSS.text)}>Request a new repair</h1>
                    <p className={"text-sm text-center w-5/6 "  + (themeCSS.text)}>Please select the relevant repair.</p>
                    <div className="grid grid-cols-1 w-full gap-2">
                    {repairDefinitions && (
                      repairDefinitions.map(function(repairDefinitions, index) {
                        return (
                          <button key={index} onClick={() => {setSelectedRepairDefinition(repairDefinitions.id)}} className={repairDefinitions.id === selectedRepairDefinition ? "p-4 w-full text-center border-blue-500 focus:outline-none border-4 rounded-lg " + (themeCSS.divCSS) : "p-4 w-full text-center focus:outline-none border-4 rounded-lg" + (themeCSS.divCSS)}>
                            {repairDefinitions.description}
                          </button>
                        )
                      })
                    )}
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4 space-y-2 lg:space-y-0">
                    <div className="flex flex-col items-center space-y-4">
                        <button className="btn btn-natural capitalize border-0 rounded-md font-semibold px-4 py-3 w-full mt-4 " onClick={() => {backToCategory()}}>Back</button>
                    </div>
                    <div className="flex flex-col items-center space-y-4">
                        <button className={"btn btn-natural capitalize text-white rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.buttonCSS)} onClick={() => {switchToContactDetailModal()}}>Next</button>
                    </div>
                </div>
                
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={contactDetailModal}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setContactDetailModal(false)}}
        className="my-20 mx-2 pb-12 lg:mx-40 overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center h-modal h-full"
        contentLabel="Profile">
        <div>
          <div className={"rounded-2xl border overflow-auto p-10 " + (themeCSS.inputCSS)}> 
                <div className="flex flex-col items-center space-y-2">
                    <h1 className={"font-bold text-2xl  w-4/6 text-center "  + (themeCSS.text)}>Request a new repair</h1>
                    <p className={"text-sm  text-center w-5/6 "  + (themeCSS.text)}>Please select the best method of contact for this repair.</p>
                    <div className="grid grid-cols-1 w-full gap-2 justify-center">
                    {contactDetails && (
                      contactDetails.map(function(contactdetail, index) {
                        return (
                          <>
                          <button key={index} onClick={() => {setSelectedContactDetail(contactdetail.referenceid)}} className={contactdetail.referenceid === selectedContactDetail ? "p-4 w-full text-center border-blue-500 focus:outline-none border-4 rounded-lg " + (themeCSS.inputCSS) : "p-4 w-full text-center focus:outline-none border-4 rounded-lg " + (themeCSS.inputCSS)}>
                            <p className={(themeCSS.text)}>({contactdetail.description}){contactdetail.reference}</p>
                          </button>
                          </>
                        )
                      })
                    )}
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4 space-y-2 lg:space-y-0">
                    <div className="flex flex-col items-center space-y-4">
                        <button className={"btn btn-natural capitalize border-0 rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.lightButtonCSS)} onClick={() => {backToRepairDefinition()}}>Back</button>
                    </div>
                    <div className="flex flex-col items-center space-y-4">
                        <button className={"btn btn-natural capitalize text-white rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.buttonCSS)} onClick={() => {switchToTimeSlotModal()}}>Next</button>
                    </div>
                </div>
                
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={timeSlotModal}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setTimeSlotModal(false)}}
        className="my-20 mx-2 pb-12 lg:mx-40 overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center h-modal h-full"
        contentLabel="TimeSlot">
        <div>
          <div className={"rounded-2xl border overflow-auto p-10 " + (themeCSS.inputCSS)}> 
                <div className="flex flex-col items-center space-y-2">
                    <h1 className={"font-bold text-2xl  w-4/6 text-center "  + (themeCSS.text)}>Request a new repair</h1>
                    <p className={"text-sm  text-center w-5/6 "  + (themeCSS.text)}>Please select your preferred time slot for this repair. We cannot guarantee this time slot will be available.</p>
                    <div className="grid grid-cols-1 w-full gap-2 justify-center">
                      <button onClick={() => {setSelectedTimeSlot("Anytime")}} className={selectedTimeSlot === "Anytime" ? "p-4 w-full text-center border-blue-500 focus:outline-none border-4 rounded-lg " + (themeCSS.divCSS) : "p-4 w-full text-center focus:outline-none border-4 rounded-lg" + (themeCSS.divCSS)}>
                        <p className={(themeCSS.text)}>Anytime</p>
                      </button>
                      <button onClick={() => {setSelectedTimeSlot("Morning")}} className={selectedTimeSlot === "Morning" ? "p-4 w-full text-center border-blue-500 focus:outline-none border-4 rounded-lg " + (themeCSS.divCSS) : "p-4 w-full text-center focus:outline-none border-4 rounded-lg" + (themeCSS.divCSS)}>
                        <p className={(themeCSS.text)}>Morning</p>
                      </button>
                      <button onClick={() => {setSelectedTimeSlot("Afternoon")}} className={selectedTimeSlot === "Afternoon" ? "p-4 w-full text-center border-blue-500 focus:outline-none border-4 rounded-lg " + (themeCSS.divCSS) : "p-4 w-full text-center focus:outline-none border-4 rounded-lg" + (themeCSS.divCSS)}>
                        <p className={(themeCSS.text)}>Afternoon</p>
                      </button>
                      <button onClick={() => {setSelectedTimeSlot("Avoid school run")}} className={selectedTimeSlot === "Avoid school run" ? "p-4 w-full text-center border-blue-500 focus:outline-none border-4 rounded-lg " + (themeCSS.divCSS) : "p-4 w-full text-center focus:outline-none border-4 rounded-lg" + (themeCSS.divCSS)}>
                        <p className={(themeCSS.text)}>Avoid school run</p>
                      </button>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4 space-y-2 lg:space-y-0">
                    <div className="flex flex-col items-center space-y-4">
                        <button className={"btn btn-natural capitalize border-0 rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.lightButtonCSS)} onClick={() => {backToContactDetailSelection()}}>Back</button>
                    </div>
                    <div className="flex flex-col items-center space-y-4">
                        <button className={"btn btn-natural capitalize text-white rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.buttonCSS)} onClick={() => {switchToDetailModal()}}>Next</button>
                    </div>
                </div>
                
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={detailModal}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setDetailModal(false)}}
        className="my-20 mx-2 pb-12 lg:mx-40 overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center h-modal h-full"
        contentLabel="Profile">
        <div>
          <div className={"rounded-2xl border overflow-auto p-10 " + (themeCSS.inputCSS)}> 
                <div className="flex flex-col items-center space-y-2">
                    <h1 className={"font-bold text-2xl w-4/6 text-center " + (themeCSS.text)}>Request a new repair</h1>
                    <p className={"text-sm text-center w-5/6 " + (themeCSS.text)}>Please provide us with some additional information about your repair.</p>
                    <div className="grid grid-cols-1 w-full gap-2">
                      <div className="grid grid-cols-1 w-full lg:space-x-4 space-y-2 lg:space-y-0">
                          <div>
                              <textarea name="repairDescription" onChange={handleChange} required placeholder="Please provide us with as much detail on the required repair as possible." type="text" className={"border-2 border-gray-500 rounded-lg w-full h-24 px-4 " + (themeCSS.divCSS)}/>
                          </div>
                      </div>
                      <div className="grid grid-cols-1 w-full lg:space-x-4 space-y-2 lg:space-y-0">
                          <div>
                              <input required placeholder="Please give us any accessibility instructions, E.G. Please use the side door." type="text" name="repairAccessNote" onChange={handleChange} className={"border-2 border-gray-400 rounded-lg w-full px-4 "+ (themeCSS.divCSS)}/>
                          </div>
                      </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4 space-y-2 lg:space-y-0">
                    <div className="flex flex-col items-center space-y-4">
                        <button className="btn btn-natural capitalize border-0 rounded-md font-semibold px-4 py-3 w-full mt-4 " onClick={() => {backToTimeSlotSelection()}}>Back</button>
                    </div>
                    <div className="flex flex-col items-center space-y-4">
                        <button className={"btn btn-natural capitalize text-white rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.buttonCSS)} onClick={() => {createRepair()}}>Request Repair</button>
                    </div>
                </div>
                
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={repairCancelModal}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setRepairCancelModal(false)}}
        className="my-20 mx-2 pb-12 lg:mx-80 overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center h-modal h-full"
        contentLabel="Profile">
        <div>
            <div className="bg-white rounded-2xl border overflow-auto p-10">
              <div className="text-center text-red-500 font-bold text-2xl">Are you sure you want to cancel repair {repairToCancel}?</div>
              <div className="grid grid-cols-2 space-x-2 pt-4">
                <button onClick={() => {setRepairCancelModal(false)}}className="bg-gray-200 text-lg text-center rounded-lg text-gray-500 w-full h-10">
                    <p className="col-span-10">Cancel</p>
                </button>
                <button onClick={() => {cancelRepair()}}className="bg-red-500 text-lg text-center rounded-lg text-white w-full h-10">
                    <p className="col-span-10 font-bold">Cancel repair</p>
                </button>
              </div>
            </div>
        </div>
      </Modal>
      <Modal
        isOpen={safetyModal}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setSafetyModal(false)}}
        className="overflow-y-hidden overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center"
        contentLabel="safetyModal">
            <div className="fixed inset-0 bg-gray-600 bg-opacity-30 overflow-y-auto h-full w-full">
                <div className="bg-white rounded-lg w-2/4 relative top-20 mx-auto border">
                    <div className="border-t-8 border-red-500 rounded-lg flex">
                    <div className="w-1/3 pt-6 flex justify-center w-24 mt-4">
                        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.2659 20.998H2.73288C2.37562 20.998 2.04551 20.8074 1.86688 20.498C1.68825 20.1886 1.68825 19.8074 1.86688 19.498L11.1329 3.49799C11.3117 3.1891 11.6415 2.9989 11.9984 2.9989C12.3553 2.9989 12.6851 3.1891 12.8639 3.49799L22.1299 19.498C22.3084 19.8072 22.3085 20.1882 22.1301 20.4975C21.9518 20.8069 21.622 20.9976 21.2649 20.998H21.2659ZM10.9999 15.998V17.998H11.9329H11.9979H12.0629H12.9979V15.998H10.9999ZM10.9999 8.99799V13.998H12.9999V8.99799H10.9999Z" fill="#CC1100"></path>
                        </svg>
                    </div>
                    <div className="w-full pt-9 pr-4">
                        <h3 className="font-bold text-xl text-red-700">Safety Notice</h3>
                        {safetyNotice && (
                          <p className="py-4 text-lg text-slate-700">{safetyNotice}</p>
                        )}
                    </div>
                    </div>

                    <div className="p-4 flex space-x-4">
                    <a onClick={() => {cancelNewRepairRequest()}} className="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm">Cancel Repair</a>
                    <a onClick={() => {setSafetyModal(false)}} className="w-1/2 px-4 py-3 text-center text-red-100 bg-red-600 rounded-lg hover:bg-red-700 hover:text-white font-bold text-sm">Continue</a>
                    </div>
                </div>
            </div>
    </Modal>
    <Modal
        isOpen={emergencyModal}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setEmergencyModal(false)}}
        className="overflow-y-hidden overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center"
        contentLabel="emergencyModal">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-30 overflow-y-auto h-full w-full">
        <div className="bg-white rounded-lg relative top-20 mx-auto w-[90%] lg:w-[60%]">
            <div className="rounded-lg content-center">
              <div className="w-full bg-red-500 rounded-t-lg flex items-center justify-center p-2">
                  <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.2659 20.998H2.73288C2.37562 20.998 2.04551 20.8074 1.86688 20.498C1.68825 20.1886 1.68825 19.8074 1.86688 19.498L11.1329 3.49799C11.3117 3.1891 11.6415 2.9989 11.9984 2.9989C12.3553 2.9989 12.6851 3.1891 12.8639 3.49799L22.1299 19.498C22.3084 19.8072 22.3085 20.1882 22.1301 20.4975C21.9518 20.8069 21.622 20.9976 21.2649 20.998H21.2659ZM10.9999 15.998V17.998H11.9329H11.9979H12.0629H12.9979V15.998H10.9999ZM10.9999 8.99799V13.998H12.9999V8.99799H10.9999Z" fill="#fff"></path>
                  </svg>
              </div>
              <div className="w-full p-4">
                  <div className="font-bold text-md text-center text-red-700">Please don't use this service if your repair would be considered an emergency.</div>
                  {settings.repair_help_text && (
                    <>
                      <div className={"text-sm text-center pt-2 text-black"}>{settings.repair_help_text}</div>
                    </>
                  )}
                  {settings.repair_emergency_number ? (
                    <>
                      <div className="font-bold text-sm text-center text-red-700 pt-2">If you are experiencing an emergency please call us immediately on the number listed below.</div>
                      <div className="font-bold text-lg text-center text-red-700 pt-2">{settings.repair_emergency_number}</div>
                    </>
                  ):(
                    <div className="font-bold text-sm text-center text-red-700 pt-2">If you are experiencing an emergency please call us immediately.</div>
                  )}
              </div>
            </div>
            <div className="p-4 grid gird-cols-1 lg:grid-cols-2 lg:space-x-4">
              <a onClick={() => {setEmergencyModal(false)}} className="lg:order-1 order-2 px-4 py-3 mb-2 text-center bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm">Cancel</a>
              <a onClick={() => {setEmergencyModal(false); setCategoryModal(true)}} className={"order-1 px-4 py-3 mb-2 mb-2 text-center bg-slate-700 text-white rounded-lg text-sm" + (themeCSS.buttonCSS)}>Request Non-Emergency Repair</a>
            </div>
          </div>
      </div>
    </Modal>
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    </>
    );
}

